import {
    GET_BANNER_REQUEST,
    GET_BANNER_SUCCESS,
    GET_BANNER_ERROR,
    CREATE_BANNER_REQUEST,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_ERROR,
    UPDATE_BANNER_REQUEST,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_ERROR,
    DELETE_BANNER_REQUEST,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_ERROR,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    banners: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    banner: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case DELETE_BANNER_REQUEST:
        case GET_BANNER_REQUEST:
        case CREATE_BANNER_REQUEST:
        case UPDATE_BANNER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_BANNER_SUCCESS:
            return {
                ...state,
                banners: {
                    ...state.banners,
                    docs: [action.payload, ...state.banners.docs],
                    totalDocs: state.banners.totalDocs + 1,
                },
                pagination: {
                    ...state.pagination,
                    total: state.banners.totalDocs + 1,
                },
                isLoading: false,
            }
        case UPDATE_BANNER_SUCCESS:
            return {
                ...state,
                banners: {
                    ...state.banners,
                    docs: state.banners.docs.map(banner => {
                        if (banner._id === action.payload._id) {
                            return action.payload;
                        } else {
                            return {...banner}
                        }
                    }),
                },
                isLoading: false,
            }
        case GET_BANNER_SUCCESS:
            return {
                ...state,
                banners: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case DELETE_BANNER_SUCCESS:
            return {
                ...state,
                banners: {
                    ...state.banners,
                    docs: state.banners.docs.filter(item => !action.payload.includes(item._id)),
                    totalDocs: state.banners.totalDocs - action.payload.length,
                },
                pagination: {
                    ...state.pagination,
                    total: state.banners.totalDocs - action.payload.length,
                },
                isLoading: false,
            }
        case DELETE_BANNER_ERROR:
        case CREATE_BANNER_ERROR:
        case GET_BANNER_ERROR:
        case UPDATE_BANNER_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}