import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Form,
    Button,
    Card,
    Select,
    Row,
    Col,
    Input,
} from 'antd';

import { getProductByCategory } from '../../actions/productActions';

const { Option } = Select;

const FilterForm = ({ category, getProduct, getProductByCategory }) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();
    const [subCategory, setSubCategory] = useState([]);

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = async values => {
        await getProduct(values);
    };

    const renderCategory = () => {
        const { isLoading, categories } = category;
        if (isLoading) {
            return <Option value="" key="0">Loading...</Option>;
        }

        return categories.map((item, i) => <Option key={i} value={item.name}>{item.name}</Option>)
    }

    const updateCategory = v => {
        const { categories } = category;
        form.setFieldsValue({ categorySub: "" });
        const [{sub}] = categories.filter(item => item.name === v)
        setSubCategory(sub);
	}

    return (
        <Card>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{
					active: "true"
				}}
            >
                <Row gutter={24}>
                    <Col span={3}>
                        <Form.Item
                            name="code"
                        >
                            <Input placeholder="กรอกรหัสสินค้า" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="productName"
                        >
                            <Input placeholder="กรอกชื่อสินค้า" />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="category"
                        >
                            <Select 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={updateCategory}
                                placeholder="กรุณาเลือกหมวดหมู่สินค้า"
                            >
                                {renderCategory()}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="categorySub"
                        >
                            <Select 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="กรุณาเลือกหมวดหมู่สินค้าย่อย"
                            >
                                <Option value="" key="0">เลือกหมวดหมู่สินค้าย่อย</Option>
                                {subCategory.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name="active"
                        >
                            <Select placeholder="กรุณาเลือกหมวดหมู่สินค้าย่อย">
                                <Option value="true" key="0">Active</Option>
                                <Option value="false" key="1">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Search
                            </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

const mapStateToProps = ({ category }) => {
    return { category };
}

FilterForm.propTypes = {
    category: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getProductByCategory })(FilterForm);