import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

const PrivateRoute = ({
    component: Component,
    auth: { isAuthenticated, isLoading, token },
    ...rest
}) => (
    <Route 
        {...rest}
        render={props => 
            !isAuthenticated && !isLoading ? (
                <Redirect to={{
                    pathname: '/signin',
                    state: { from: props.location }
                }} />
            ) : (
                token !== null && <Component {...props} />
            )
        }
    />
)

const mapStateToProps = state => ({
    auth: state.auth
});

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(PrivateRoute)