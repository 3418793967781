import React, { useEffect, useState } from 'react';
import { Button, Alert, Row, Col } from 'antd'
import {
	PlusSquareOutlined
} from '@ant-design/icons';

import Editor from '../Quill';
import { getHtml, createHtml } from '../../actions/htmlActions';
import PageHeader from './PageHeader';

const Html = ({ type }) => {
    const [isError, setIsError] = useState(false);
    const [html, setHtml] = useState(null);
    const [errMessage, setErrMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editorHtml, setEditorHtml] = useState(html);
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const _getHtml = async () => {
            setIsLoading(true);
            setIsError(false);
			setIsComplete(false);
			setIsDisableBtn(false);
            try {
                const _html = await getHtml(type);
                if (_html) {
                    setHtml(_html);
                } else {
                    setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
                }
            } catch (error) {
                console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
            }
            setIsLoading(false);
        }
        _getHtml();
    }, [type]);

    const updateHtml = editHtml => {
        setEditorHtml(editHtml);
    }

    const submit = async () => {
        const obj = {
            type,
            content: editorHtml,
        }
    
        const isCreateHtml = await createHtml(obj);
        if (isCreateHtml.status) {
            setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
        } else {
            setIsError(true);
			setIsComplete(false);
			setErrMessage('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
        }
    }

    const render = () => {
        if (isLoading) {
            return <div>Loading...</div>
        }

        return (
            <div className="banner">
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <PageHeader type={type} />
                    </Col>
                </Row>
                <Row style={{ padding: '0px 20px 0px 20px' }}>
                    <Col span={24}>
                        <Editor html={html.data !== null ? html.data.content : ''} updateHtml={updateHtml} />
                        <Button disabled={isDisableBtn} onClick={submit} style={{ marginTop: '20px' }} size="large" type="primary">
                            <PlusSquareOutlined /> Submit
                        </Button>
                    </Col>
                </Row>
                {isComplete ? <Alert style={{ marginTop: '20px' }} message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
                {isError ? <Alert style={{ marginTop: '20px' }} message={errMessage} type="error" showIcon /> : null}
            </div>
        )
    }

    return (
        <React.Fragment>
            {render()}
        </React.Fragment>
    )
}

export default Html;