import {
    SET_MENU_ACTIVE,
    SET_OPEN,
} from '../actionTypes';

export const setMenuActive = selected => async dispatch => {
    dispatch({ 
        type: SET_MENU_ACTIVE,
        payload: {
            selected,
        }
    });
}

export const setOpen = opened => async dispatch => {
    dispatch({ 
        type: SET_OPEN,
        payload: {
            opened,
        }
    });
}