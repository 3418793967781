import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const singupAdmin = signupObj => async dispatch => {
    dispatch({ type: CREATE_USER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation signupAdmin($signupObj: SignupInput!) {
            signupAdmin(signupInput: $signupObj) {
                _id
                firstname
                lastname
                email
                phone
                created
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                signupObj,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.signupAdmin) {
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: res.data.signupAdmin,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: CREATE_USER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const getUser = ({ current, pageSize }, search) => async dispatch => {
    dispatch({ type: GET_USER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            admins(page: ${current}, limit: ${pageSize}, search: ${search})  {
                docs {
                    _id
                    firstname
                    lastname
                    email
                    phone
                    active
                    created
                }
                totalDocs
                page
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.admins) {
            dispatch({
                type: GET_USER_SUCCESS,
                payload: res.data.admins,
            })
        } else {
            dispatch({
                type: GET_USER_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const getUserById = async id => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer  ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            user(id: "${id}") {
                _id
                firstname
                lastname
                email
                phone
                created
                active
            }
        }`
    }
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => res)
    .catch(err => console.log('err', err));
}

export const updatePassword = (id, newPassword) => async dispatch => {
    dispatch({ type: UPDATE_USER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updatePasswordAdmin($newPassword: String!, $id: String!) {
            updatePasswordAdmin(newPassword: $newPassword, id: $id) {
                _id
                firstname
                lastname
                name
                email
                phone
                active
                created
            }
        }`
        return fetch(URL_GRAPHQL, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                query,
                variables: {
                    newPassword,
                    id,
                }
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.data && res.data !== null && res.data.updatePasswordAdmin) {
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: res.data.updatePasswordAdmin,
                });
                return {
                    status: true,
                    message: null,
                }
            } else {
                dispatch({
                    type: UPDATE_USER_ERROR,
                });
                return {
                    status: false,
                    message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
                }
            }
        })
        .catch(err => console.log('err', err));
}

export const updateProfile = (id, obj) => async dispatch => {
    dispatch({ type: UPDATE_USER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateProfileAdmin($obj: UserInput!, $id: String!) {
            updateProfileAdmin(userInput: $obj, id: $id) {
                _id
                firstname
                lastname
                name
                email
                phone
                active
                created
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                obj,
                id,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateProfileAdmin) {
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: res.data.updateProfileAdmin,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_USER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}


export const deleteMultipleAdmin = idArr => async dispatch => {
    dispatch({ type: DELETE_USER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deleteMultipleAdmin($idArr: [String!]!) {
            deleteMultipleAdmin(id: $idArr)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                idArr
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.deleteMultipleAdmin) {
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: idArr,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: DELETE_USER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}