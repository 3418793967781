/* Product Reducer */
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_BY_CATEGORY = 'GET_PRODUCT_BY_CATEGORY';
export const GET_PRODUCT_BY_CATEGORY_ERROR = 'GET_PRODUCT_BY_CATEGORY_ERROR';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';
export const DELETE_MULTI_PRODUCT_REQUEST = 'DELETE_MULTI_PRODUCT_REQUEST';
export const DELETE_MULTI_PRODUCT_SUCCESS = 'DELETE_MULTI_PRODUCT_SUCCESS';

/* Catogory Reducer */
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const CREATE_CATEGORY_SUB_REQUEST = 'CREATE_CATEGORY_SUB_REQUEST';
export const CREATE_CATEGORY_SUB_SUCCESS = 'CREATE_CATEGORY_SUB_SUCCESS';
export const CREATE_CATEGORY_SUB_ERROR = 'CREATE_CATEGORY_SUB_ERROR';
export const UPDATE_CATEGORY_SUB_REQUEST = 'UPDATE_CATEGORY_SUB_REQUEST';
export const UPDATE_CATEGORY_SUB_ERROR = 'UPDATE_CATEGORY_SUB_ERROR';
export const DELETE_CATEGORY_SUB_REQUEST = 'DELETE_CATEGORY_SUB_REQUEST';
export const DELETE_CATEGORY_SUB_SUCCESS = 'DELETE_CATEGORY_SUB_SUCCESS';
export const DELETE_CATEGORY_SUB_ERROR = 'DELETE_CATEGORY_SUB_ERROR';


/* Order Reducer */
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS';

/* Customer Reducer */
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

/* Menu Reducer */
export const SET_MENU_ACTIVE = 'SET_MENU_ACTIVE';
export const SET_OPEN = 'SET_OPEN';

/* Banner Reducer */
export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';
export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_ERROR = 'CREATE_BANNER_ERROR';
export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_ERROR = 'UPDATE_BANNER_ERROR';
export const DELETE_BANNER_REQUEST = 'DELETE_BANNER_REQUEST';
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
export const DELETE_BANNER_ERROR = 'DELETE_BANNER_ERROR';


/* User Reducer */
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
/* Auth Reducer */
export const AUTHENTICATE = 'AUTHENTICATE';
export const DEAUTHENTICATE = 'DEAUTHENTICATE';

/* Coupon Reducer */
export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_ERROR = 'GET_COUPON_ERROR';

/* Maintenance Setting Reducer */
export const GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST';
export const GET_MAINTENANCE_SUCCESS = 'GET_MAINTENANCE_SUCCESS';
export const GET_MAINTENANCE_ERROR = 'GET_MAINTENANCE_ERROR';
export const UPDATE_MAINTENANCE_REQUEST = 'UPDATE_MAINTENANCE_REQUEST';
export const UPDATE_MAINTENANCE_SUCCESS = 'UPDATE_MAINTENANCE_SUCCESS';
export const UPDATE_MAINTENANCE_ERROR = 'UPDATE_MAINTENANCE_ERROR';

/* Promotion period Reducer */
export const CREATE_PROMOTION_PERIOD_REQUEST = 'CREATE_PROMOTION_PERIOD_REQUEST';
export const CREATE_PROMOTION_PERIOD_SUCCESS = 'CREATE_PROMOTION_PERIOD_SUCCESS';
export const CREATE_PROMOTION_PERIOD_ERROR = 'CREATE_PROMOTION_PERIOD_ERROR';
export const GET_PROMOTION_PERIOD_REQUEST = 'GET_PROMOTION_PERIOD_REQUEST';
export const GET_PROMOTION_PERIOD_SUCCESS = 'GET_PROMOTION_PERIOD_SUCCESS';
export const GET_PROMOTION_PERIOD_ERROR = 'GET_PROMOTION_PERIOD_ERROR';
export const DELETE_PROMOTION_PERIOD_REQUEST = 'DELETE_PROMOTION_PERIOD_REQUEST';
export const DELETE_PROMOTION_PERIOD_SUCCESS = 'DELETE_PROMOTION_PERIOD_SUCCESS';
export const DELETE_PROMOTION_PERIOD_ERROR = 'DELETE_PROMOTION_PERIOD_ERROR';
