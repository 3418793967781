import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import PageHeader from './PageHeader';
import TableMaintenance from './TableMaintenance';
import './Maintenance.scss';

import { getMaintenance } from '../../actions/settingActions';

const Maintenance = ({ getMaintenance, setting }) => {
    useEffect(() => {
        const _getMaintenance = async () => {
            await getMaintenance();
        }
        _getMaintenance();
    }, []);

    return (
        <div className="maintenance">
            <Row>
                <Col span={24}>
                    <PageHeader />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableMaintenance
                        loading={setting.isLoading}
                        data={setting.setting.maintenances}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ setting }) => {
    return { setting }
}

Maintenance.propTypes = {
    getMaintenance: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getMaintenance })(Maintenance);