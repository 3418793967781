import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Table,
	Input,
    Button,
    Tag,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { setActiveMaintenance } from '../../actions/settingActions';

class TableMaintenance extends React.Component {
	constructor() {
		super();
		this.state = {
			searchText: '',
			searchedColumn: '',
			selectedRowKeys: [],
			deleteLoading: false,
		};
    }
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					ค้นหา
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					ล่างค่า
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
		this.state.searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		) : (
			text
		),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
    };
    
    setActive = id => {
        this.props.setActiveMaintenance(id);
    }

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	render() {
		const { data, loading } = this.props;
		const columns = [
			{
				title: 'ชื่อ',
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
            },
            {
				title: 'สถานะ',
				dataIndex: 'active',
				key: 'active',
                ...this.getColumnSearchProps('active'),
                render: active => active ? <Tag color="#117A65">ใช้งาน</Tag> : <Tag color="#CB4335">ปิดการใช้งาน</Tag>
            },
            {
				title: 'Action',
                key: 'action',
                dataIndex: 'key',
                render: key => <Button onClick={() => this.setActive(key)} type="primary">เปลี่ยนสถานะ</Button>
            },
		];
		return (
			<div>
				<Table columns={columns} loading={loading} dataSource={data ? data.map((item, i) => {return { key: item._id, ...item }}) : []} />
			</div>
		);
  	}
}

TableMaintenance.propTypes = {
	setActiveMaintenance: PropTypes.func.isRequired,
}

export default connect(null, { setActiveMaintenance })(TableMaintenance);