import React from 'react';
import { PageHeader } from 'antd'

const PageHeaders = ({ type }) => {

    const transFormSubTitle = () => {
        let subTitle;
        switch (type) {
            case 'condition':
                subTitle = 'ข้อกำหนดเงื่อนไข';
                break;

            case 'howtobuy':
                subTitle = 'ขั้นตอนการสั่งซื้อ';
                break;
            
            case 'howtopay':
                subTitle = 'วิธีการชำระเงิน';
                break;

            case 'policy':
                subTitle = 'นโยบายความเป็นส่วนตัว';
                break;

            case 'contactus':
                subTitle = 'ข้อมูลติดต่อเรา';
                break;

            case 'return-product':
                subTitle = 'การคืนสินค้า';
                break;

            case 'cookie':
                subTitle = 'นโยบายการใช้งานคุ้กกี้';
                break;
        
            default:
                break;
        }
        return subTitle;
    }

    return (
        <PageHeader
            // onBack={() => null}
            title="ระบบจัดการข้อความ (HTML)"
            subTitle={transFormSubTitle()}
        />
    );
}

export default PageHeaders;