import {
    CREATE_PROMOTION_PERIOD_SUCCESS,
    CREATE_PROMOTION_PERIOD_REQUEST,
    CREATE_PROMOTION_PERIOD_ERROR,
    GET_PROMOTION_PERIOD_REQUEST,
    GET_PROMOTION_PERIOD_SUCCESS,
    GET_PROMOTION_PERIOD_ERROR,
    DELETE_PROMOTION_PERIOD_REQUEST,
    DELETE_PROMOTION_PERIOD_SUCCESS,
    DELETE_PROMOTION_PERIOD_ERROR,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    promotionPeriods: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    promotionPeriod: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_PROMOTION_PERIOD_REQUEST:
        case CREATE_PROMOTION_PERIOD_REQUEST:
        case DELETE_PROMOTION_PERIOD_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case DELETE_PROMOTION_PERIOD_SUCCESS:
            return {
                ...state,
                promotionPeriods: state.promotionPeriods.filter(item => !action.payload.includes(item._id)),
                isLoading: false,
            }
        case CREATE_PROMOTION_PERIOD_SUCCESS:
            return {
                ...state,
                promotionPeriods: [action.payload, ...state.promotionPeriods],
                isLoading: false,
            }
        case GET_PROMOTION_PERIOD_SUCCESS:
            return {
                ...state,
                promotionPeriods: action.payload,
                isLoading: false,
            }
        case GET_PROMOTION_PERIOD_ERROR:
        case CREATE_PROMOTION_PERIOD_ERROR:
        case DELETE_PROMOTION_PERIOD_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}