import React from 'react';
import {
    Form,
    Select
} from 'antd';

const { Option } = Select;

const SubCategoryDropdown = ({ categories, categorySelected }) => {
    
    const renderOption = () => {
        if (categorySelected !== null) {
            const [ { sub } ] = categories.filter(item => item.name === categorySelected);
            if (sub.length === 0) {
                return <Option key="1" value="">ไม่มีหมวดหมู่สินค้าย่อย</Option>
            }
            return sub.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>);
        } else {
            return <Option key="1" value="">กรุณาเลือกหมวดหมู่สินค้าหลักก่อน</Option>
        }
    }

    if (categories.length === 0) {
        return <div>Loading...</div>
    }

    return (
        <Form.Item
            name="categorySub"
            label="หมวดหมู่สินค้าย่อย"
            rules={[{ required: true, message: 'กรุณาเลือกหมวดหมู่สินค้าย่อย' }]}
        >
            <Select 
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="กรุณาเลือกหมวดหมู่สินค้าหลักก่อน"
            >
                <Option key="0" value="">เลือกหมวดหมู่สินค้าย่อย</Option>
                {renderOption()}
            </Select>
        </Form.Item>
    )
}

export default SubCategoryDropdown;