import React from 'react';
import { PageHeader } from 'antd'

const PageHeaders = () => {
    return (
        <PageHeader
            // onBack={() => null}
            title="ระบบจัดการลูกค้า (Customer)"
            extra={[
                
            ]}
        />
    );
}

export default PageHeaders;