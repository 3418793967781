import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import {
    GET_BANNER_REQUEST,
    GET_BANNER_SUCCESS,
    GET_BANNER_ERROR,
    CREATE_BANNER_REQUEST,
    CREATE_BANNER_SUCCESS,
    CREATE_BANNER_ERROR,
    UPDATE_BANNER_REQUEST,
    UPDATE_BANNER_SUCCESS,
    UPDATE_BANNER_ERROR,
    DELETE_BANNER_REQUEST,
    DELETE_BANNER_SUCCESS,
    DELETE_BANNER_ERROR,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const getBanner = ({ current, pageSize }, search) => async dispatch => {
    dispatch({ type: GET_BANNER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            getBannerAll(page: ${current}, limit: ${pageSize}, search: ${search}) {
                docs {
                    _id
                    name
                    urlLink
                    urlImage
                    priority
                    active
                }
                totalDocs
                page
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.getBannerAll) {
            dispatch({
                type: GET_BANNER_SUCCESS,
                payload: res.data.getBannerAll,
            })
        } else {
            dispatch({
                type: GET_BANNER_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const createBanner = bannerObj => async dispatch => {
    dispatch({ type: CREATE_BANNER_REQUEST });
    bannerObj.priority = Number(bannerObj.priority);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation createBanner($bannerObj: BannerInput!) {
            createBanner(bannerInput: $bannerObj) {
                _id
                name
                urlLink
                urlImage
                priority
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                bannerObj,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.createBanner) {
            dispatch({
                type: CREATE_BANNER_SUCCESS,
                payload: res.data.createBanner,
            });
            return {
                status: true,
                message: null,
                id: res.data.createBanner._id,
            }
        } else {
            dispatch({
                type: CREATE_BANNER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const getBannerById = async (status, id) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    
    let query = {
        query: `{
            getBannerById(active: ${status}, id: "${id}") {
                _id
                name
                urlLink
                urlImage
                priority
                active
            }
        }`
    }
    
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => res)
    .catch(err => console.log('err', err));
}

export const updateBannerId = (bannerObj, id) => async dispatch => {
    dispatch({ type: UPDATE_BANNER_REQUEST });
    bannerObj.priority = Number(bannerObj.priority);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateBannerById($bannerObj: BannerInput!, $id: String!) {
            updateBannerById(bannerInput: $bannerObj, id: $id) {
                _id
                name
                urlLink
                urlImage
                priority
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                bannerObj,
                id,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateBannerById) {
            dispatch({
                type: UPDATE_BANNER_SUCCESS,
                payload: res.data.updateBannerById,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_BANNER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const deleteMultipleBanner = idArr => async dispatch => {
    dispatch({ type: DELETE_BANNER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deleteMultipleBanner($idArr: [String!]!) {
            deleteMultipleBanner(id: $idArr)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                idArr
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.deleteMultipleBanner) {
            dispatch({
                type: DELETE_BANNER_SUCCESS,
                payload: idArr,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: DELETE_BANNER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}