import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	DatePicker,
	Alert,
} from 'antd';

import { createPromotionPeriod } from '../../actions/promotionPeriodActions';
import { PromotionType } from '../../constants/enum/promotion-type.enum';

const { RangePicker } = DatePicker;

const AddPromotionPeriod = ({ open, handleOpenAdd, promotionType, createPromotionPeriod }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenAdd();
    }
    const onFinish = async values => {
		if (values.period && values.period.length === 2) {
			values.type = promotionType;
			const isCraeted = await createPromotionPeriod(values);
			if (isCraeted.status) {
				setIsError(false);
				setIsComplete(true);
				setIsDisableBtn(true);
			} else {
				setIsError(true);
				setIsComplete(false);
				setErrMessage('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
			}
		}
    }

    const renderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="period"
							label="ช่วงเวลา"
							rules={[
								{ required: true, message: 'กรุณาเลือกช่วงเวลา' },
							]}
						>
							<RangePicker format={'DD-MM-YYYY HH:mm:ss'} showTime />
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทึก
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}
    
    return (
        <Drawer
			title="เพิ่มช่วงเวลา"
			width={420}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

AddPromotionPeriod.propTypes = {
	createPromotionPeriod: PropTypes.func.isRequired,
}

export default connect(null, { createPromotionPeriod })(AddPromotionPeriod);