import React, { useEffect, useState } from 'react';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Button,
    Upload,
    Modal,
    message,
    List,
    Typography,
    Divider,
} from 'antd';
import { STORAGE_TOKEN, API_URL } from '../../constants/config';
import { DECODE_DATA, ENCODE_DATA } from '../../services/storage';
import { exportTemplate, getPromotionPeriod } from '../../actions/promotionPeriodActions';
import { getCookie } from '../../actions/authActions';
import { PromotionType } from '../../constants/enum/promotion-type.enum';

const { Dragger } = Upload;

const ModalImport = ({ handleCancel, modalVisible, rowId, promotionType, getPromotionPeriod }) => {
    const [, forceUpdate] = useState();
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        forceUpdate({});
    }, []);

    const closeModal = () => {
        handleCancel();
    }

    const handleExportTemplate = () => {
        exportTemplate(promotionType);
    }

    const props = {
        name: 'file',
        multiple: false,
        headers: {
            'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
        },
        action: `${API_URL}/${promotionType === PromotionType.SHOCK_SALE ? 'promotionShockSale' : 'promotionMember'}/import/${rowId}`,
        onChange(info) {
            setIsError(false);
            const { status } = info.file;

            if (status !== 'uploading') {
                if (status === 'error') {
                    setIsError(true);
                    if (info.file.response && info.file.response.errors && info.file.response.errors.length > 0) {
                        setErrors(info.file.response.errors);
                    } else {
                        setErrors(['Please contact admin!!']);
                    }
                }
            }

            if (status === 'done') {
                getPromotionPeriod(promotionType);
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Modal
            visible={modalVisible}
            title="นำเข้าข้อมูลสินค้า"
            onCancel={closeModal}
            width={1000}
            footer={[
                <Button key="back" onClick={closeModal}>
                    ยกเลิก
                </Button>,
            ]}
            >
                <Button style={{marginBottom: '20px' }} block size="middle" type="primary" onClick={e => handleExportTemplate()} key="1"><DownloadOutlined /> Export template</Button>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click หรือ drop ไฟล์เพื่ออัพโหลดข้อมูล</p>
                </Dragger>
                {
                    isError ? (
                        <React.Fragment>
                            <Divider style={{ marginTop: '20px' }} orientation="left"><span style={{ color: 'red' }}>Error !!</span></Divider>
                            <List
                                style={{ marginTop: '20px' }}
                                bordered
                                dataSource={errors}
                                renderItem={item => (
                                    <List.Item>
                                    <Typography.Text mark>[Error]</Typography.Text> {item}
                                    </List.Item>
                                )}
                            />
                        </React.Fragment>
                    ) : null
                }
        </Modal>
    );
};

ModalImport.propTypes = {
    getPromotionPeriod: PropTypes.func.isRequired,
}

export default connect(null, { getPromotionPeriod })(ModalImport);