import {
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDERS_SUCCESS,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    orders: {},
    pagination: {
        current: 1,
        pageSize: 10,
    },
    order: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ORDER_REQUEST:
        case GET_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case UPDATE_ORDERS_SUCCESS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    docs: state.orders.docs.map(order => {
                        let rs = action.payload.filter(x => String(x._id) === String(order._id));
                        if (rs.length > 0) {
                            return rs[0];
                        } else {
                            return order;
                        }
                    }),
                },
                isLoading: false,
            }
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    docs: state.orders.docs.map(order => {
                        if (order._id === action.payload._id) {
                            return action.payload;
                        } else {
                            return {...order}
                        }
                    }),
                },
                isLoading: false,
            }
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case GET_ORDER_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}