import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import {
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDERS_SUCCESS,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const getOrder = ({ current, pageSize }, search) => async dispatch => {
    dispatch({ type: GET_ORDER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer  ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            orders(page: ${current}, limit: ${pageSize}, where: ${search}) {
                docs {
                    _id
                    orderNumber
                    User {
                        firstname
                        lastname
                        email
                        phone
                    }
                    coupon
                    transport
                    totalPrice
                    payment
                    state
                    created
                }
                totalDocs
                page
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.orders) {
            dispatch({
                type: GET_ORDER_SUCCESS,
                payload: res.data.orders,
            })
        } else {
            dispatch({
                type: GET_ORDER_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const changeStateOrders = stateObj => async dispatch => {
    dispatch({ type: UPDATE_ORDER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer  ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation changeStateOrders($stateObj: ChangeStateInputs!) {
            changeStateOrders(ChangeStateInputs: $stateObj) {
                _id
                orderNumber
                User {
                    firstname
                    lastname
                    email
                    phone
                }
                coupon
                transport
                totalPrice
                payment
                state
                created
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                stateObj,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.changeStateOrders && res.data.changeStateOrders.length > 0) {
            dispatch({
                type: UPDATE_ORDERS_SUCCESS,
                payload: res.data.changeStateOrders,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const changeStateOrder = stateObj => async dispatch => {
    dispatch({ type: UPDATE_ORDER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation changeStateOrder($stateObj: ChangeStateInput!) {
            changeStateOrder(ChangeStateInput: $stateObj) {
                _id
                orderNumber
                User {
                    firstname
                    lastname
                    email
                    phone
                }
                coupon
                transport
                totalPrice
                payment
                state
                created
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                stateObj,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.changeStateOrder && res.data.changeStateOrder !== null) {
            dispatch({
                type: UPDATE_ORDER_SUCCESS,
                payload: res.data.changeStateOrder,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const getOrderById = async id => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            getOrderById(id: "${id}", admin: true) {
                _id
                orderNumber
                user
                products {
                    product
                    code
                    name
                    quantity
                    price
                    weight
                    isPromotionShockSale
                }
                User {
                    firstname
                    lastname
                    email
                    phone
                    birth
                    phone
                    sex
                    created
                    oceanMemberCard
                }
                coupon
                transport
                Cost {
                    totalProduct
                    coupon
                    transfer
                }
                totalPrice
                addressShipping {
                    _id
                    firstname
                    lastname
                    phone
                    addr1
                    village
                    subDistrict
                    district
                    province
                    zip
                }
                addressInvoice {
                    _id
                    firstname
                    lastname
                    phone
                    addr1
                    village
                    subDistrict
                    district
                    province
                    zip
                }
                payment
                state
                created
            }
        }`
    }
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => res)
    .catch(err => console.log('err', err));
}