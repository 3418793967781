import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';
import * as _ from 'lodash';

import PageHeader from '../Shared/PageHeader';
import TableCoupon from './TableCoupon';

import { getCoupons } from '../../actions/couponActions';
import { exportCouponWithData } from '../../actions/couponActions';

const Banner = ({ getCoupons, coupon }) => {
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filter, setFilter] = useState('{}');

    useEffect(() => {
        const _getCoupons = async () => {
            await getCoupons(pagination, filter);
        }
        _getCoupons();
    }, [pagination]);

    const handleTableChange = (paginate, filters, extra) => {
        if (!_.isEmpty(filters, true)) {
            let searchStr = '{';
            for (const property in filters) {
                searchStr += `${property}: "${filters[property]}",`;
            }
            searchStr = searchStr.slice(0, -1);
            searchStr += '}';
            setFilter(searchStr);
            if (extra.action === 'filters') {
                setPagination({ current: 1, pageSize: 10 });
            } else {
                setPagination(paginate);
            }
        } else {
            setPagination(paginate);
            setFilter('{}');
        }
    }

    const exportfile = () => {
        exportCouponWithData();
    }

    return (
        <div className="banner">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการคูปอง (Coupon)" subTitle="" btn={{ 
                        text: 'Export File xlsx (export คูปอง ทั้งหมด)',
                        fnc: exportfile,
                        visible: true,
                        icon: <DownloadOutlined />,
                        size: 'middle',
                        type: 'primary'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableCoupon
                        loading={coupon.isLoading}
                        data={coupon.coupons}
                        pagination={coupon.pagination}
                        handleTableChange={handleTableChange}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ coupon }) => {
    return {coupon};
}

Banner.propTypes = {
    getCoupons: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getCoupons })(Banner);