import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Checkbox,
	Alert
} from 'antd';

import UploadBannerDrawer from './UploadBannerDrawer';
import { createBanner } from '../../actions/bannerAction';

const AddBanner = ({ open, handleOpenAdd, createBanner }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const [childUpload, setChildUpload] = useState(false);
	const [bannerId, setBannerId] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenAdd();
	}
	
	const onChildClose = () => {
		setChildUpload(false);
	}

    const onFinish = async values => {
		const isCreateBanner = await createBanner(values);
		if (isCreateBanner.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
			setChildUpload(true);
			setBannerId(isCreateBanner.id);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
		}
    };

    const renderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
				initialValues={{
					active: false,
				}}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="ชื่อแบนเนอร์"
							rules={[{ required: true, message: 'กรุณากรอกชื่อแบนเนอร์' }]}
						>
						<Input placeholder="กรอกชื่อแบนเนอร์" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="priority"
							label="Priority"
							rules={[{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }]}
						>
						<Input placeholder="กรอก Priority" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="urlLink"
							label="Link URL"
						>
						<Input placeholder="กรอก URL" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item  name="active" valuePropName="checked">
							<Checkbox>ใช้งาน</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทึกข้อมูลเพื่อเพิ่มรูปแบนเนอร์
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}

    return (
		<React.Fragment>
			<Drawer
				title="เพิ่มแบนเนอร์"
				width={520}
				placement="right"
				closable={true}
				onClose={onClose}
				visible={open}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
					{renderForm()}
				</div>
			</Drawer>
			<UploadBannerDrawer id={bannerId} onClose={onChildClose} open={childUpload} />
		</React.Fragment>
    )
}

const mapStateToProps = ({ banner }) => {
    return { banner };
}

AddBanner.propTypes = {
	createBanner: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { createBanner })(AddBanner);