import React, { useState } from 'react';
import { STORAGE_TOKEN, API_URL } from '../constants/config';
import { 
    Upload,
    message,
    Alert,
    Typography,
} from 'antd';
import { 
    LoadingOutlined, 
    PlusOutlined 
} from '@ant-design/icons';

import { ENCODE_DATA, DECODE_DATA } from '../services/storage';
import { getCookie } from '../actions/authActions';

const { Text } = Typography;

const UploadForm = ({ description, moduleUpload, id, text, afterUpload = null }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }
      
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
          message.error('Image must smaller than 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                setIsCompleted(true);
                setImageUrl(imageUrl)
                afterUpload !== null && afterUpload(imageUrl);
                setLoading(false);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">{text}</div>
        </div>
    );

    return (
        <React.Fragment>
        <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${API_URL}/${moduleUpload}/upload/${id}`}
            headers={{
                'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
            }}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
        <Text type="danger">{description}</Text>
        {isCompleted && <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon />}
        </React.Fragment>
    )
}

export default UploadForm;