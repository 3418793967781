import React from 'react';
import { connect } from 'react-redux';
import { 
    Table,
    Input,
    Button,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { 
    SearchOutlined,
} from '@ant-design/icons';

import DeleteSubCategory from './DeleteSubCategory';

class TableSubCategory extends React.Component {
	constructor() {
		super();
		this.state = {
			searchText: '',
			searchedColumn: '',
			modalVisible: false,
			categorySubCode: '',
			categorySubName: '',
		};
	}

	handleCancel = () => {
		this.setState({ modalVisible: false });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
		this.state.searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		) : (
			text
		),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
    };

    handleDelete = (code, name) => {
        this.setState({
			categorySubCode: code, 
			categorySubName: name,
			modalVisible: true,
		});
    }
    
    handleEdit = (code, name) => {
		const { handleOpenView, updateCategorySubName, updateCategorySubCode } = this.props;
        handleOpenView(true);
        updateCategorySubName(name);
        updateCategorySubCode(code);
    }

	render() {
		const { data, loading } = this.props;
		const { modalVisible, categorySubCode, categorySubName } = this.state;
		const columns = [
            {
				title: 'รหัสหมวดหมู่ย่อย',
				dataIndex: 'code',
				key: 'code',
				...this.getColumnSearchProps('code'),
			},
			{
				title: 'ชื่อหมวดหมู่ย่อย',
				dataIndex: 'name',
                key: 'name',
                width: '60%',
				...this.getColumnSearchProps('name'),
			},
			{
				title: 'Action',
                key: 'active',
                render: (text, record) => (
                    <React.Fragment>
                        <Button style={{ marginRight: '20px' }} onClick={e => this.handleEdit(record.code, record.name)} type="dashed">แก้ไขข้อมูล</Button>
                        <Button onClick={e => this.handleDelete(record.code, record.name)} type="dashed" danger>ลบข้อมูล</Button>
                    </React.Fragment>
                ),
			}
		];
		return (
			<React.Fragment>
				<Table columns={columns} loading={loading} dataSource={data.length > 0 && data[0].sub && data[0].sub.length > 0 ? data[0].sub.map((item, i) => { return { key: item.code, ...item } }) : []} />
				{modalVisible && <DeleteSubCategory categorySubCode={categorySubCode} categorySubName={categorySubName} handleCancel={this.handleCancel} modalVisible={modalVisible} />}
			</React.Fragment>
		)
  	}
}

export default connect(null, {})(TableSubCategory);