import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

import Order from '../components/Order';

const OrderPage = props => {
    const { Content } = Layout;
    const { type } = props.match.params;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <Order type={type} />
            </Content>
        </MainLayout>
    )
}

export default OrderPage;