import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { 
	Table,
	Input,
    Button,
    Tag,
} from 'antd';
import * as _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

import { deleteMultipleAdmin } from '../../../actions/userAction';

class TableUser extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
			deleteLoading: false,
		};
    }
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	delete = async () => {
		// this.setState({ deleteLoading: true });
		const { selectedRowKeys } = this.state;
		const { deleteMultipleAdmin } = this.props;
		const isDeleted = await deleteMultipleAdmin(selectedRowKeys);
		
		if (isDeleted) {
			setTimeout(() => {
				this.setState({
					selectedRowKeys: [],
					deleteLoading: false,
				});
			}, 1000);
		} else {
			alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
		}
	}

	handleTableChange = (pagination, filters, sort, extra) => {
		const pickFilters = _.pickBy(filters, _.identity);
		const { handleTableChange } = this.props;
		handleTableChange(pagination, pickFilters, extra);
	};

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	rowClick = (record) => {
		const { handleOpenView, updateUserId } = this.props;
        return {
            onClick: event => {
				handleOpenView(true);
				updateUserId(record._id);
            }
        }
    }

	render() {
		const { selectedRowKeys, deleteLoading } = this.state;
		const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		const columns = [
			{
				title: 'ชื่อ',
				dataIndex: 'firstname',
				key: 'firstname',
				...this.getColumnSearchProps('firstname'),
            },
            {
				title: 'นามสกุล',
				dataIndex: 'lastname',
				key: 'lastname',
                ...this.getColumnSearchProps('lastname'),
			},
			{
				title: 'เบอร์โทรศัพท์',
				dataIndex: 'phone',
				key: 'phone',
				...this.getColumnSearchProps('phone'),
			},
			{
				title: 'อีเมล',
				dataIndex: 'email',
				key: 'email',
                ...this.getColumnSearchProps('email'),
            },
            {
				title: 'วันที่สร้าง',
				dataIndex: 'created',
				key: 'created',
                ...this.getColumnSearchProps('created'),
                render: created => created ? moment(created).format('DD/MM/YYYY HH:mm:ss') : null,
            },
            {
				title: 'สถานะ',
				dataIndex: 'active',
				key: 'active',
				filters: [
					{
						text: 'ใช้งาน',
						value: 'active',
					},
					{
						text: 'ปิดการใช้งาน',
						value: 'inactive',
					},
				],
                render: active => active ? <Tag color="#117A65">ใช้งาน</Tag> : <Tag color="#CB4335">ปิดการใช้งาน</Tag>
            },
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>
					<Button style={{ marginRight: '25px' }} type="primary" onClick={this.delete} disabled={!hasSelected} loading={deleteLoading}>
						ลบข้อมูล
					</Button>
					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					onRow={this.rowClick}
					loading={loading}
					dataSource={data && data.docs ? data.docs.map((item, i) => {return { key: item._id, ...item }}) : []}
					pagination={pagination}
					onChange={this.handleTableChange}
				/>
			</div>
		);
  	}
}

TableUser.propTypes = {
	deleteMultipleAdmin: PropTypes.func.isRequired,
}

export default connect(null, { deleteMultipleAdmin })(TableUser);