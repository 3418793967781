import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Checkbox,
	Alert
} from 'antd';

import CategoryDropdown from './CategoryDropdown';
import SubCategoryDropdown from './SubCategoryDropdown';
import UploadProductDrawer from './UploadProductDrawer';

import { createProduct } from '../../actions/productActions';

const AddProduct = ({ open, handleOpenAdd, categoryReducer, createProduct }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState([]);
	const [categorySelected, setCategorySelected] = useState(null);
	const [childUpload, setChildUpload] = useState(false);
	const [productId, setProductId] = useState(null);
    const [form] = Form.useForm();
    
    useEffect(() => {		
		const _getCategory = async () => {
            setIsLoading(true);
			setCategories(categoryReducer.categories);
            setCategory(categoryReducer.categories.map(item => item.name));
            setIsLoading(false);
		}
		_getCategory();
	}, []);

    const onClose = () => {
        handleOpenAdd();
	}
	
	const onChildClose = () => {
		setChildUpload(false);
	}

    const onFinish = async values => {
		const isCreateProduct = await createProduct(values);
		if (isCreateProduct.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
			setChildUpload(true);
			setProductId(isCreateProduct.id);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isCreateProduct.message);
		}
    };

    const updateCategory = v => {
		form.setFieldsValue({ categorySub: "" });
		setCategorySelected(v);
    }
    
    const renderForm = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}

		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
				initialValues={{
					active: true,
					isPromotion: false,
					isRecommend: false
				}}
			>
				<Row gutter={16}>
					<Col span={12}>
						<CategoryDropdown updateCategory={updateCategory} category={category} />
					</Col>
					<Col span={12}>
						<SubCategoryDropdown categories={categories} categorySelected={categorySelected} />
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="code"
							label="รหัสสินค้า"
							rules={[
								{ required: true, message: 'กรุณากรอกรหัสสินค้า' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกรหัสสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="ชื่อสินค้า"
							rules={[{ required: true, message: 'กรุณากรอกชื่อสินค้า' }]}
						>
						<Input placeholder="กรอกชื่อสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="price"
							label="ราคา"
							rules={[
								{ required: true, message: 'กรุณากรอกราคาสินค้าเป็นตัวเลขเท่านั้น' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกราคาสินค้า" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="specialPrice"
							label="ราคาพิเศษ"
							rules={[
								{ required: true, message: 'กรุณากรอกราคาพิเศษเป็นตัวเลขเท่านั้น' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกราคาพิเศษ" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="balance"
							label="จำนวนสินค้าคงเหลือ"
							rules={[
								{ required: true, message: 'กรุณากรอกจำนวนสินค้าคงเหลือเป็นตัวเลขเท่านั้น' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกจำนวนสินค้าคงเหลือ" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="weight"
							label="น้ำหนัก"
							rules={[
								{ required: true, message: 'กรุณากรอกน้ำหนักสินค้าเป็นตัวเลขเท่านั้น' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกน้ำหนักสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="detail"
							label="รายละเอียด"
							rules={[
								{
									required: true,
									message: 'กรุณากรอกรายละเอียดสินค้า',
								},
							]}
						>
							<Input.TextArea rows={4} placeholder="กรอกรายละเอียดสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item  name="isPromotion" valuePropName="checked">
							<Checkbox>สินค้าโปรโมชั่น</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item  name="isRecommend" valuePropName="checked">
							<Checkbox>สินค้าแนะนำ</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item  name="active" valuePropName="checked">
							<Checkbox>Active</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit">
					บันทึกข้อมูล
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}

    return (
		<React.Fragment>
			<Drawer
				title="เพิ่มสินค้า"
				width={720}
				placement="right"
				closable={true}
				onClose={onClose}
				visible={open}
				bodyStyle={{ paddingBottom: 80 }}
			>
				<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
					{renderForm()}
				</div>
			</Drawer>
			<UploadProductDrawer productId={productId} onClose={onChildClose} open={childUpload} />
		</React.Fragment>
    )
}

const mapStateToProps = ({ category }) => {
    return { categoryReducer: category };
}

AddProduct.propTypes = {
	categoryReducer: PropTypes.object.isRequired,
	createProduct: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { createProduct })(AddProduct);