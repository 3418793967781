import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
    Alert,
	Checkbox,
	Card,
} from 'antd';

import { getUserById, updateProfile, updatePassword } from '../../../actions/userAction';

const ViewUser = ({ id, open, handleOpenView, updateProfile, updatePassword }) => {
	const [isDisableBtn, setIsDisableBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [errMessage, setErrMessage] = useState(null);

	const [isProfileError, setProfileIsError] = useState(false);
	const [isProfileComplete, setIsProfileComplete] = useState(false);
	const [profileErrMessage, setProfileErrMessage] = useState(null);

	const [isPasswordError, setPasswordIsError] = useState(false);
	const [isPasswordComplete, setIsPasswordComplete] = useState(false);
	const [passwordErrMessage, setPasswordErrMessage] = useState(null);

	const [user, setUser] = useState(null);
	const [formProfile] = Form.useForm();
	const [formPassword] = Form.useForm();
	
	useEffect(() => {
		const _getUserById = async () => {
			setIsLoading(true);
			try {
				const _user = await getUserById(id);
				if (_user.data && _user.data !== null && _user.data.user !== null) {
					const data = _user.data.user;
					setUser(data);
				} else {
					setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
				}	
			} catch (error) {
				console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
			}
			setIsLoading(false);
		}

		_getUserById();
	},[]);

    const onClose = () => {
        handleOpenView(false);
    }

    const onFinishProfile = async values => {
		delete values.email;
		values.phone = values.phone === undefined ? '' : values.phone;

		const isUpdateUser = await updateProfile(id, values);
		if (isUpdateUser.status) {
			setProfileIsError(false);
			setIsProfileComplete(true);
			setIsDisableBtn(true);
		} else {
			setProfileIsError(true);
			setIsProfileComplete(false);
			setProfileErrMessage(isUpdateUser.message);
		}
	};
	
	const onFinishPassword = async values => {
		
		const transform = values.password;

		const isUpdateUser = await updatePassword(id, transform);
		if (isUpdateUser.status) {
			setPasswordIsError(false);
			setIsPasswordComplete(true);
		} else {
			setPasswordIsError(true);
			setIsPasswordComplete(false);
			setPasswordErrMessage(isUpdateUser.message);
		}
	};

    const renderForm = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}

		if (isError) {
			return <div>{errMessage}</div>
		}

		return (
			<React.Fragment>
				<Card title="Password" headStyle={{ color: '#cc1c1c', border: 0 }} style={{ marginBottom: '20px', borderColor: '#cc1c1c' }}>
				<Form
						form={formPassword}
						layout="vertical" 
						hideRequiredMark 
						onFinish={onFinishPassword}
						>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="password"
										label="รหัสผ่าน"
										rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
									>
									<Input.Password placeholder="Password / รหัสผ่าน" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="confirm"
										label="ยืนยันรหัสผ่าน"
										dependencies={['password']}
										rules={[
											{
												required: true,
												message: 'กรุณากรอกยืนยันรหัสผ่าน',
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject('รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง');
												},
											}),
										]}
									>
									<Input.Password placeholder="Confirm Password / ยืนยันรหัสผ่าน" />
									</Form.Item>
								</Col>
							</Row>
							<Button style={{marginBottom: '25px'}} type="primary" htmlType="submit">
								บันทึกข้อมูล
							</Button>
							{isPasswordComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
							{isPasswordError ? <Alert message={passwordErrMessage} type="error" showIcon /> : null}
					</Form>
				</Card>
			
				<Card title="Profile" headStyle={{ color: '#cc1c1c', border: 0 }} style={{ marginBottom: '20px', borderColor: '#cc1c1c' }}>
					<Form
						form={formProfile}
						layout="vertical" 
						hideRequiredMark 
						onFinish={onFinishProfile}
						initialValues={{
							email: user.email,
							firstname: user.firstname,
							lastname: user.lastname,
							phone: user.phone,
							active: user.active,
						}}
						>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="email"
										label="อีเมล"
										rules={[{ required: true, message: 'กรุณากรอกอีเมล' }]}
									>
									<Input readOnly disabled placeholder="Email / อีเมล" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="firstname"
										label="ชื่อ"
										rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
									>
									<Input placeholder="ชื่อ / First Name" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="lastname"
										label="นามสกุล"
										rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
									>
									<Input placeholder="นามสกุล / Last Name" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="phone"
										label="เบอร์โทรศัทท์"
										rules={[
											{
											  	pattern: new RegExp(/^\d{9,10}$/),
											  	message: "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
											}
										]}
									>
									<Input placeholder="เบอร์โทรศัทท์ / Phone" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item  name="active" valuePropName="checked">
										<Checkbox>เปิดใช้งาน (Active)</Checkbox>
									</Form.Item>
								</Col>
							</Row>
							
							<Button disabled={isDisableBtn} style={{marginBottom: '25px'}} type="primary" htmlType="submit">
								บันทึกข้อมูล
							</Button>
							{isProfileComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
							{isProfileError ? <Alert message={profileErrMessage} type="error" showIcon /> : null}
					</Form>
				</Card>
			</React.Fragment>
		)
	}
    
    return (
        <Drawer
			title="แก้ไขข้อมูลผู้ใช้งาน"
			width={720}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

ViewUser.propTypes = {
	updateProfile: PropTypes.func.isRequired,
	updatePassword: PropTypes.func.isRequired,
}

export default connect(null, { updateProfile, updatePassword })(ViewUser);