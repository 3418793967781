import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import * as _ from 'lodash';

import PageHeader from './PageHeader';
import TableCustomer from './TableCustomer';
import ViewCustomer from './ViewCustomer';
import './Customer.scss';

import { getCustomer } from '../../actions/customerAction';

const Customer = ({ getCustomer, customer }) => {
    const [openView, setOpenView] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filter, setFilter] = useState('{}');
    
    const handleOpenView = status => {
        setOpenView(status);
    }

    const handleTableChange = (paginate, filters, extra) => {
        if (!_.isEmpty(filters, true)) {
            let searchStr = '{';
            for (const property in filters) {
                searchStr += `${property}: "${filters[property]}",`;
            }
            searchStr = searchStr.slice(0, -1);
            searchStr += '}';
            setFilter(searchStr);
            if (extra.action === 'filters') {
                setPagination({ current: 1, pageSize: 10 });
            } else {
                setPagination(paginate);
            }
        } else {
            setPagination(paginate);
            setFilter('{}');
        }
    }

    const updateCustomerId = id => {
        setCustomerId(id);
    }

    useEffect(() => {
        const _getCustomer = async () => {
            await getCustomer(pagination, filter);
        }
        _getCustomer();
    }, [pagination]);

    return (
        <div className="customer">
            <Row>
                <Col span={24}>
                    <PageHeader />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableCustomer
                        handleTableChange={handleTableChange}
                        loading={customer.isLoading}
                        data={customer.customers}
                        pagination={customer.pagination}
                        handleOpenView={handleOpenView}
                        updateCustomerId={updateCustomerId}
                    />
                </Col>
            </Row>
            {openView && <ViewCustomer id={customerId} handleOpenView={handleOpenView} open={openView} />}
        </div>
    )
}

const mapStateToProps = ({ customer }) => {
    return { customer }
}

Customer.propTypes = {
    getCustomer: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getCustomer })(Customer);