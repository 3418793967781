import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Table,
    Input,
    Button,
    Modal,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { 
    SearchOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import { deleteCategory } from '../../actions/categoryAction';

const { confirm } = Modal;

class TableCategory extends React.Component {
	constructor() {
		super();
		this.state = {
			searchText: '',
			searchedColumn: '',
		};
    }

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
		this.state.searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		) : (
			text
		),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};
	
	deleteError = () => {
		Modal.error({
			title: 'ไม่สามารถลบ หมวดหมู่ นี้ได้',
			content: 'ไม่สามารถลบ หมวดหมู่ นี้ได้เนื่องจากยังมีสินค้าอยู่ในหมวดหมู่นี้ กรุณาย้ายสินค้าทั้งหมดออกจากหมวดหมู่นี้ก่อน',
		});
	}

    handleDelete = (code, name) => {
		const { deleteCategory } = this.props;
		const b = this.deleteError;
        confirm({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
            icon: <ExclamationCircleOutlined />,
            okText: 'ใช่',
            okType: 'danger',
            cancelText: 'ไม่',
            async onOk() {
				const isDeleted = await deleteCategory(code, name);
				if (!isDeleted.status) {
					b();
				}
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    
    handleEdit = (code, name) => {
		const { handleOpenView, updateCategoryName, updateCategoryCode } = this.props;
        handleOpenView(true);
        updateCategoryName(name);
        updateCategoryCode(code);
    }

	render() {
        const { category } = this.props;
		const columns = [
            {
				title: 'รหัสหมวดหมู่',
				dataIndex: 'code',
				key: 'code',
				...this.getColumnSearchProps('code'),
			},
			{
				title: 'ชื่อหมวดหมู่',
				dataIndex: 'name',
                key: 'name',
                width: '60%',
				...this.getColumnSearchProps('name'),
			},
			{
				title: 'Action',
                key: 'active',
                render: (text, record) => (
                    <React.Fragment>
                        <Button style={{ marginRight: '20px' }} onClick={e => this.handleEdit(record.code, record.name)} type="dashed">แก้ไขข้อมูล</Button>
                        <Button onClick={e => this.handleDelete(record.code, record.name)} type="dashed" danger>ลบข้อมูล</Button>
                    </React.Fragment>
                ),
			}
		];
		return <Table columns={columns} loading={category.isLoading} dataSource={category.categories ? category.categories.map((item, i) => {return { key: item.code, ...item }}) : []} />
  	}
}

const mapStateToProps = ({ category }) => {
    return { category }
}

TableCategory.propTypes = {
	category: PropTypes.object.isRequired,
	deleteCategory: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { deleteCategory })(TableCategory);