import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import * as _ from 'lodash';

import PageHeader from './PageHeader';
import TableOrder from './TableOrder';
import ViewOrder from './ViewOrder';

import { getOrder } from '../../actions/orderActions';

import './Order.scss';

const Order = ({ type, getOrder, order }) => {
    const [openView, setOpenView] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filter, setFilter] = useState('{}');

    const handleOpenView = status => {
        setOpenView(status);
    }

    const handleTableChange = (paginate, filters, extra) => {
        if (!_.isEmpty(filters, true)) {
            let searchStr = '{';
            for (const property in filters) {
                if (property === 'orderNumber' || property === 'totalPrice') {
                    searchStr += `${property}: ${filters[property]},`;
                } else {
                    searchStr += `${property}: "${filters[property]}",`;
                }
            }
            searchStr = searchStr.slice(0, -1);
            searchStr += '}';
            setFilter(searchStr);
            if (extra.action === 'filters') {
                setPagination({ current: 1, pageSize: 10 });
            } else {
                setPagination(paginate);
            }
        } else {
            setPagination(paginate);
            setFilter('{}');
        }
    }

    const updateOrderId = id => {
        setOrderId(id);
    }

    useEffect(() => {
        const _getOrder = async () => {
            await getOrder(pagination, filter);
        }
        _getOrder();
    }, [pagination]);

    const renderTable = () => {
        let transFormOrder;
        // if (type === 'new') {
        //     const newOrder = !_.isEmpty(order.orders) ? order.orders.docs.filter(item => item.state === 'PAYMENT') : [];
        //     const cloneOrder = _.cloneDeep(order.orders);
        //     cloneOrder.docs = newOrder;
        //     return (
        //         <TableOrder
        //             handleTableChange={handleTableChange}
        //             pagination={order.pagination}
        //             key="1"
        //             loading={order.isLoading}
        //             data={cloneOrder}
        //             handleOpenView={handleOpenView}
        //             updateOrderId={updateOrderId}
        //         />
        //     )
        // } else {
            transFormOrder = order.orders;
            return (
                <TableOrder
                    handleTableChange={handleTableChange}
                    pagination={order.pagination}
                    key="2"
                    loading={order.isLoading}
                    data={transFormOrder}
                    handleOpenView={handleOpenView}
                    updateOrderId={updateOrderId}
                />
            )
        // }
    }

    
    
    return (
        <div className="order">
            <Row>
                <Col span={24}>
                    <PageHeader />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {renderTable()}
                </Col>
            </Row>
            {openView && <ViewOrder id={orderId} handleOpenView={handleOpenView} open={openView} />}
        </div>
    )
}

const mapStateToProps = ({ order }) => {
    return { order }
}

Order.propTypes = {
    getOrder: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getOrder })(Order);