import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import {
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,
    CREATE_CATEGORY_SUB_REQUEST,
    CREATE_CATEGORY_SUB_ERROR,
    UPDATE_CATEGORY_SUB_REQUEST,
    UPDATE_CATEGORY_SUB_ERROR,
    DELETE_CATEGORY_SUB_REQUEST,
    DELETE_CATEGORY_SUB_SUCCESS,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const checkExistingSubCategory = categorySub => {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    
    let query = {
        query: `{
            getProducts(categorySub: "${categorySub}", limit: 1) {
                _id
                name
            }
        }`
    }
    
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => {
        console.log(res);
        if (res.data && res.data !== null && res.data.getProducts && res.data.getProducts.length > 0) {
            return true;
        } else {
            return false;
        }
    })
    .catch(err => console.log('err', err));
}

export const updateCategorySub = obj => async dispatch => {
    const codeSub = obj.categorySubCode;
    const nameNewSub = obj.categorySubNewName;
    const nameOldSub = obj.categorySubOldName;
    const code = obj.categoryCode;
    const name = obj.categoryName;
    dispatch({ type: UPDATE_CATEGORY_SUB_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateCategorySub($codeSub: String!, $nameNewSub: String!, $nameOldSub: String!, $code: String!, $name: String!) {
            updateCategorySub(codeSub: $codeSub, nameNewSub: $nameNewSub, nameOldSub: $nameOldSub, code: $code, name: $name) {
                code
                name
                status
                sub {
                    code
                    name
                    status
                }
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                codeSub,
                nameNewSub,
                nameOldSub,
                code,
                name,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateCategorySub) {
            dispatch({
                type: UPDATE_CATEGORY_SUCCESS,
                payload: res.data.updateCategorySub,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_CATEGORY_SUB_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const updateCategory = obj => async dispatch => {
    const code = obj.code;
    const oldName = obj.oldName;
    const newName = obj.category;
    dispatch({ type: UPDATE_CATEGORY_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateCategory($oldName: String!, $newName: String!, $code: String!) {
            updateCategory(oldName: $oldName, newName: $newName, code: $code) {
                code
                name
                status
                sub {
                    code
                    name
                    status
                }
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                oldName,
                newName,
                code,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateCategory) {
            dispatch({
                type: UPDATE_CATEGORY_SUCCESS,
                payload: res.data.updateCategory,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_CATEGORY_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const deleteSubCategory = obj => async dispatch => {
    dispatch({ type: DELETE_CATEGORY_SUB_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deleteSubCategory($category: String!, $categorySub: String!, $categorySubName: String!, $categorySubCode: String!, $type: String!) {
            deleteSubCategory(category: $category, categorySub: $categorySub, categorySubName: $categorySubName, categorySubCode: $categorySubCode, type: $type)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                ...obj
            }
        })
    })
    .then(res => res.json())
    .then(res => {

        if (res.data && res.data.deleteSubCategory) {
            dispatch({
                type: DELETE_CATEGORY_SUB_SUCCESS,
                payload: obj
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: DELETE_CATEGORY_ERROR,
            });
            return {
                status: false,
                message: res.errors && res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const deleteCategory = (code, name) => async dispatch => {
    dispatch({ type: DELETE_CATEGORY_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deleteCategory($code: String!, $name: String!) {
            deleteCategory(code: $code, name: $name)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                code,
                name
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.deleteCategory) {
            const obj = { code, name };
            dispatch({
                type: DELETE_CATEGORY_SUCCESS,
                payload: obj,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: DELETE_CATEGORY_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
    
}

export const createCategorySub = obj => async dispatch => {
    const codeSub = obj.categorySubCode;
    const nameSub = obj.categorySub;
    const code = obj.code;
    const name = obj.category;
    dispatch({ type: CREATE_CATEGORY_SUB_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation createCategorySub($codeSub: String!, $nameSub: String!, $code: String!, $name: String!) {
            createCategorySub(codeSub: $codeSub, nameSub: $nameSub, code: $code, name: $name) {
                code
                name
                status
                sub {
                    code
                    name
                    status
                }
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                codeSub,
                nameSub,
                code,
                name
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.createCategorySub) {
            dispatch({
                type: UPDATE_CATEGORY_SUCCESS,
                payload: res.data.createCategorySub,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: CREATE_CATEGORY_SUB_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const createCategory = obj => async dispatch => {
    const code = obj.code;
    const name = obj.category;
    dispatch({ type: CREATE_CATEGORY_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation createCategory($code: String!, $name: String!) {
            createCategory(code: $code, name: $name) {
                code
                name
                status
                sub {
                    code
                    name
                    status
                }
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                code,
                name
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.createCategory) {
            dispatch({
                type: CREATE_CATEGORY_SUCCESS,
                payload: res.data.createCategory,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: CREATE_CATEGORY_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}