import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Table,
	Input,
	Button,
	Tag
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import MoveCategory from './MoveCategory';
import { MONEY_FORMAT, NUMBER_FORMAT } from '../../services/number';
import { deleteMultipleProduct } from '../../actions/productActions';

class TableProduct extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
			deleteLoading: false,
			modalVisible: false,
		};
	}

	showModal = () => {
		this.setState({ modalVisible: true });
	}

	handleCancel = () => {
		this.setState({ modalVisible: false });
	};

	clearSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	delete = async () => {
		this.setState({ deleteLoading: true });
		const { selectedRowKeys } = this.state;
		const { deleteMultipleProduct } = this.props;
		const isDeleted = await deleteMultipleProduct(selectedRowKeys);
		
		if (isDeleted) {
			setTimeout(() => {
				this.setState({
					selectedRowKeys: [],
					deleteLoading: false,
				});
			}, 1000);
		} else {
			alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
		}
	};
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	rowClick = (record) => {
		const { handleOpenView, updateProductId, updateProductStatus } = this.props;
        return {
            onClick: event => {
				handleOpenView(true);
				updateProductId(record._id);
				updateProductStatus(record.active);
            }
        }
    }

	handleTableChange = (pagination, filters) => {
		const { handleTableChange } = this.props;
		handleTableChange(pagination);	
	};

	render() {
		const { deleteLoading, selectedRowKeys, modalVisible } = this.state;
		const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		const columns = [
			{
				title: 'Category / Sub Category',
				dataIndex: 'category',
				key: 'category',
				width: '30%',
				render: (text, row) => {
					return (
						`${text} / ${row.categorySub}`
					)
				}
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				width: '30%',
			},
			{
				title: 'Price / Special Price',
				dataIndex: 'price',
				key: 'price',
				width: '20%',
				render: (text, row) => {
					return (
						`${MONEY_FORMAT(text, 2, true)} / ${MONEY_FORMAT(row.specialPrice, 2, true)}`
					)
				}
			},
			{
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				render: balance => NUMBER_FORMAT(balance)
			},
			{
				title: 'Status',
				dataIndex: 'active',
				key: 'active',
				render: active => active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>
			}
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>
					<Button style={{ marginRight: '25px' }} type="primary" onClick={this.delete} disabled={!hasSelected} loading={deleteLoading}>
						ลบข้อมูล
					</Button>
					<Button type="primary" onClick={this.showModal} disabled={!hasSelected}>
						ย้ายหมวดหมู่
					</Button>

					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					onRow={this.rowClick}
					loading={loading}
					dataSource={data.docs ? data.docs.map((item, i) => {return { key: item._id, ...item }}) : []} 
					onChange={this.handleTableChange}
					pagination={pagination}
				/>
				<MoveCategory clearSelected={this.clearSelected} selectedRowKeys={selectedRowKeys} handleCancel={this.handleCancel} modalVisible={modalVisible} />
			</div>
		);
  	}
}

TableProduct.propTypes = {
	deleteMultipleProduct: PropTypes.func.isRequired,
}

export default connect(null, { deleteMultipleProduct })(TableProduct);