import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { URL_GRAPHQL, STORAGE_TOKEN, API_URL } from '../constants/config';

import {
    CREATE_PROMOTION_PERIOD_REQUEST,
    CREATE_PROMOTION_PERIOD_SUCCESS,
    CREATE_PROMOTION_PERIOD_ERROR,
    GET_PROMOTION_PERIOD_ERROR,
    GET_PROMOTION_PERIOD_SUCCESS,
    GET_PROMOTION_PERIOD_REQUEST,
    DELETE_PROMOTION_PERIOD_REQUEST,
    DELETE_PROMOTION_PERIOD_SUCCESS,
    DELETE_PROMOTION_PERIOD_ERROR,
} from '../actionTypes';
import moment from 'moment';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';
import { PromotionType } from '../constants/enum/promotion-type.enum';

var FileSaver = require('file-saver');

export const getPromotionPeriod = (promotionType = PromotionType.SHOCK_SALE)  => async dispatch => {
    dispatch({ type: GET_PROMOTION_PERIOD_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            promotionPeriods(input: {
                type: ${promotionType}
            }) {
                _id
                startDate
                endDate
                type
                pages {
                    totalDocs
                }
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.promotionPeriods) {
            dispatch({
                type: GET_PROMOTION_PERIOD_SUCCESS,
                payload: res.data.promotionPeriods,
            })
        } else {
            dispatch({
                type: GET_PROMOTION_PERIOD_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const createPromotionPeriod = createdObj => async dispatch => {
    const period = {
        startDate: moment(createdObj.period[0]).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(createdObj.period[1]).format('YYYY-MM-DD HH:mm:ss'),
        type: createdObj.type,
    }
    dispatch({ type: CREATE_PROMOTION_PERIOD_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation createPromotionPeriod($period: PromotionPeriodInput!) {
            createPromotionPeriod(promotionPeriodInput: $period) {
                _id
                startDate
                endDate
                type
                pages {
                    totalDocs
                }
            }
        }`;
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                period,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.createPromotionPeriod) {
            dispatch({
                type: CREATE_PROMOTION_PERIOD_SUCCESS,
                payload: res.data.createPromotionPeriod,
            });
            return {
                status: true,
                message: null,
                id: res.data.createPromotionPeriod._id,
            }
        } else {
            dispatch({
                type: CREATE_PROMOTION_PERIOD_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const exportTemplate = promotionType => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    let apiPath, fileName;
    if (promotionType === PromotionType.SHOCK_SALE) {
        apiPath = 'promotionShockSale';
        fileName = 'template_shock_sale.xlsx';
    } else {
        apiPath = 'promotionMember';
        fileName = 'template_promotion_member.xlsx';
    }
    axios.get(
        `${API_URL}/${apiPath}/export`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName);
        });
}

export const exportWithData = (periodId, promotionType) => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    let apiPath, fileName;
    if (promotionType === PromotionType.SHOCK_SALE) {
        apiPath = 'promotionShockSale';
        fileName = 'output_shock_sale.xlsx';
    } else {
        apiPath = 'promotionMember';
        fileName = 'output_promotion_member.xlsx';
    }
    axios.get(
        `${API_URL}/${apiPath}/export/${periodId}`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fileName);
        });
}

export const deletePromotionPeriod = id => async dispatch => {
    dispatch({ type: DELETE_PROMOTION_PERIOD_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deletePromotionPeriod($id: String!) {
            deletePromotionPeriod(id: $id)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                id
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.deletePromotionPeriod) {
            dispatch({
                type: DELETE_PROMOTION_PERIOD_SUCCESS,
                payload: id,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: DELETE_PROMOTION_PERIOD_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}
