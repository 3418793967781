import React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { 
	Table,
	Input,
    Button,
    Tag,
} from 'antd';
import * as _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

// import { deleteMultipleBanner } from '../../actions/bannerAction';

class TableCoupon extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
			deleteLoading: false,
		};
    }

	handleTableChange = (pagination, filters, sort, extra) => {
		const pickFilters = _.pickBy(filters, _.identity);
		const { handleTableChange } = this.props;
		handleTableChange(pagination, pickFilters, extra);
	};
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	delete = async () => {
		// this.setState({ deleteLoading: true });
		// const { selectedRowKeys } = this.state;
		// const { deleteMultipleBanner } = this.props;
		// const isDeleted = await deleteMultipleBanner(selectedRowKeys);
		
		// if (isDeleted.status) {
		// 	setTimeout(() => {
		// 		this.setState({
		// 			selectedRowKeys: [],
		// 			deleteLoading: false,
		// 		});
		// 	}, 1000);
		// } else {
		// 	alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
		// }
	}

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	render() {
		const { selectedRowKeys } = this.state;
        const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const columns = [
			{
				title: 'รหัสคูปอง   ',
				dataIndex: 'code',
				key: 'code',
				// ...this.getColumnSearchProps('code'),
            },
			{
				title: 'วันที่เริ่มใช้งาน',
				dataIndex: 'startDate',
                key: 'startDate',
                // ...this.getColumnSearchProps('startDate'),
                render: date => date ? moment.utc(date).format('DD/MM/YYYY') : null
            },
            {
				title: 'วันหมดอายุ',
				dataIndex: 'expireDate',
                key: 'expireDate',
                // ...this.getColumnSearchProps('expireDate'),
                render: date => date ? moment.utc(date).format('DD/MM/YYYY') : null
            },
            {
				title: 'ส่วนลด',
				dataIndex: 'discount',
                key: 'discount',
                // ...this.getColumnSearchProps('discount'),
            },
            {
				title: 'ราคาสั่งซื้อขั้นต่ำ',
				dataIndex: 'minimumPurchase',
                key: 'minimumPurchase',
                // ...this.getColumnSearchProps('minimumPurchase'),
			},
            {
				title: 'การใช้งาน',
				dataIndex: 'used',
				key: 'used',
				// filters: [
				// 	{
				// 		text: 'ยังไม่ได้ใช้งาน',
				// 		value: true,
				// 	},
				// 	{
				// 		text: 'ถูกใช้งานแล้ว',
				// 		value: false,
				// 	},
				// ],
                render: used => used ? <Tag color="#CB4335">ถูกใช้งานแล้ว</Tag> : <Tag color="">ยังไม่ได้ใช้งาน</Tag>
            },
            {
				title: 'สถานะ',
				dataIndex: 'state',
				key: 'state',
				// filters: [
				// 	{
				// 		text: 'คูปองหมดอายุ',
				// 		value: 'คูปองหมดอายุ',
				// 	},
				// 	{
				// 		text: 'ถูกใช้งานแล้ว',
				// 		value: 'ถูกใช้งานแล้ว',
				// 	},
				// 	{
				// 		text: 'ยังไม่ถึงวันที่ใช้งาน',
				// 		value: 'ยังไม่ถึงวันที่ใช้งาน',
				// 	},
				// 	{
				// 		text: 'ใช้งานได้',
				// 		value: 'ใช้งานได้',
				// 	},
				// ],
				// onFilter: (value, record) => record.state.indexOf(value) === 0,
                render: state => {
					let color = ""
					switch (state) {
						case "คูปองหมดอายุ":
						case "ถูกใช้งานแล้ว":
							color = "#CB4335"
							break;
						case "ยังไม่ถึงวันที่ใช้งาน":
							color = "warning"
							break;
						case "ใช้งานได้":
							color = "#117A65"
							break;
						default:
							break;
					}
					return <Tag color={color}>{state}</Tag>
				}
            },
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>
					{/* <Button style={{ marginRight: '25px' }} type="primary" onClick={this.delete} disabled={!hasSelected} loading={deleteLoading}>
						ลบข้อมูล
					</Button>
					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span> */}
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					loading={loading}
					dataSource={data && data.docs ? data.docs.map((item, i) => {return { key: item._id, ...item }}) : []}
					pagination={pagination}
					onChange={this.handleTableChange}
				/>
			</div>
		);
  	}
}

TableCoupon.propTypes = {
	// deleteMultipleBanner: PropTypes.func.isRequired,
}

export default connect(null, {})(TableCoupon);