import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

import ShockSale from '../components/ShockSale';

const ShockSalePage = () => {
    const { Content } = Layout;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <ShockSale />
            </Content>
        </MainLayout>
    )
}

export default ShockSalePage;