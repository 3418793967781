import axios from 'axios';
import { STORAGE_TOKEN, API_URL, URL_GRAPHQL } from '../constants/config';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

import {
    GET_COUPON_REQUEST,
    GET_COUPON_SUCCESS,
    GET_COUPON_ERROR,
} from '../actionTypes';

var FileSaver = require('file-saver');

export const exportCouponTemplate = () => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    axios.get(
        `${API_URL}/coupon/export`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'coupon.xlsx');
        });
}

export const exportCouponWithData = () => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    axios.get(
        `${API_URL}/coupon/exportWithData`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'coupon.xlsx');
        });
}

export const getCoupons = ({ current, pageSize }) => async dispatch => {
    dispatch({ type: GET_COUPON_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            coupons(page: ${current}, limit: ${pageSize}) {
                docs {
                    _id
                    code
                    name
                    startDate
                    expireDate
                    discount
                    minimumPurchase
                    used
                    state
                }
                totalDocs
                page
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.coupons) {
            dispatch({
                type: GET_COUPON_SUCCESS,
                payload: res.data.coupons,
            })
        } else {
            dispatch({
                type: GET_COUPON_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}