import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const Editor = ({ html, updateHtml }) => {
    const [editorHtml, setEditorHtml] = useState(html);

    useEffect(() => {
        updateHtml(html);
    }, []);

    const handleChange = content => {
        setEditorHtml(content);
        updateHtml(content);
    }

    return (
        <React.Fragment>
            <ReactQuill 
                theme="snow"
                onChange={handleChange}
                value={editorHtml}
                modules={Editor.modules}
                formats={Editor.formats}
                bounds={'.app'}
            />
        </React.Fragment>
      )
}

Editor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: false,
    }
  }
  
Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
];
  
Editor.propTypes = {
    placeholder: PropTypes.string,
}
    
export default Editor;