import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Layout,
    Menu,
    Dropdown,
    Avatar,
} from 'antd';
import { 
    DownOutlined, 
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import { signout } from '../../actions/authActions';
import './Header.scss';

const Header = ({ signout, auth }) => {

    const menu = (
        <Menu className="dropdown-item-menu">
            <Menu.Item key="0">
                <a href="#">Welcome {auth.user.firstname && auth.user.firstname} {auth.user.lastname && auth.user.lastname}!</a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" onClick={signout}><LogoutOutlined /> Logout</Menu.Item>
        </Menu>
    );

    const { Header } = Layout;
    return (
        <Header className="site-layout-sub-header-background">
            <div className="dropdown-menu">
                <div className="avatar">
                    <Avatar icon={<UserOutlined />} />
                </div>
                <div className="dropdown-item">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {auth.user.firstname && auth.user.firstname} {auth.user.lastname && auth.user.lastname} <DownOutlined />
                        </a>
                    </Dropdown>
                </div>
            </div>
        </Header>
    );
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}

Header.propTypes = {
    signout: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { signout })(Header);