import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'

import FormSignIn from './FormSignIn';
import { HOME } from '../../constants/routes';

const SignIn = ({ auth }) => {
    // const [showFormForgot, setShowFormForgot] = useState(false);

    if (auth.isAuthenticated) {
        return <Redirect to={HOME} />;
    }

    // const handleSetShowFormForgot = v => {
    //     setShowFormForgot(v);
    // }

    return (
        <React.Fragment>
            <FormSignIn />
        </React.Fragment>
    )
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}

SignIn.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, {})(SignIn);