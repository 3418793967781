import {
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_ERROR,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    customers: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    customer: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER_REQUEST:
        case UPDATE_CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: {
                    ...state.customers,
                    docs: state.customers.docs.map(customer => {
                        if (customer._id === action.payload._id) {
                            return action.payload;
                        } else {
                            return {...customer}
                        }
                    }),
                },
                isLoading: false,
            }
        case UPDATE_CUSTOMER_ERROR:
        case GET_CUSTOMER_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}