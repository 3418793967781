import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Alert,
} from 'antd';

import { singupAdmin } from '../../../actions/userAction';
// import { createCategory } from '../../actions/categoryAction';

const AddUser = ({ open, handleOpenAdd, singupAdmin }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenAdd();
    }

    const onFinish = async values => {
		delete values.confirm

		const isCreateUser = await singupAdmin(values);
		if (isCreateUser.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isCreateUser.message);
		}
    };

    const renderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="email"
							label="อีเมล"
							rules={[
								{
									type: 'email',
									message: 'รูปแบบของอีเมลไม่ถูกต้อง',
								  },
								  {
									required: true,
									message: 'กรุณากรอกอีเมล',
								  }
							]}
						>
						<Input placeholder="Email / อีเมล" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="password"
							label="รหัสผ่าน"
							rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
						>
						<Input.Password placeholder="Password / รหัสผ่าน" />
						</Form.Item>
					</Col>
                    <Col span={12}>
						<Form.Item
							name="confirm"
                            label="ยืนยันรหัสผ่าน"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกยืนยันรหัสผ่าน',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง');
                                    },
                                }),
                            ]}
						>
						<Input.Password placeholder="Confirm Password / ยืนยันรหัสผ่าน" />
						</Form.Item>
					</Col>
				</Row>
                <Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="firstname"
							label="ชื่อ"
							rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
						>
						<Input placeholder="ชื่อ / First Name" />
						</Form.Item>
					</Col>
                    <Col span={12}>
						<Form.Item
							name="lastname"
							label="นามสกุล"
							rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
						>
						<Input placeholder="นามสกุล / Last Name" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="phone"
							label="เบอร์โทรศัพท์"
							// rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
						>
						<Input placeholder="เบอร์โทรศัพท์ / Phone" />
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit">
					บันทึกข้อมูล
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}
    
    return (
        <Drawer
			title="เพิ่มผู้ใช้งาน"
			width={720}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

AddUser.propTypes = {
	singupAdmin: PropTypes.func.isRequired,
}

export default connect(null, { singupAdmin })(AddUser);