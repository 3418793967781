import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Alert
} from 'antd';

import { createCategory } from '../../actions/categoryAction';

const AddCategory = ({ open, handleOpenAdd, createCategory }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenAdd();
    }

    const onFinish = async values => {
		const isCreateCategory = await createCategory(values);
		if (isCreateCategory.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isCreateCategory.message);
		}
    };

    const renderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="code"
							label="รหัส"
							rules={[
								{ required: true, message: 'กรุณากรอกรหัสหมวดหมู่สินค้า' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกรหัสหมวดหมู่สินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="category"
							label="ชื่อหมวดหมู่สินค้า"
							rules={[{ required: true, message: 'กรุณากรอกชื่อหมวดหมู่สินค้า' }]}
						>
						<Input placeholder="กรอกชื่อหมวดหมู่สินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทึก
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}
    
    return (
        <Drawer
			title="เพิ่มหมวดหมู่"
			width={420}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

AddCategory.propTypes = {
	createCategory: PropTypes.func.isRequired,
}

export default connect(null, { createCategory })(AddCategory);