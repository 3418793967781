import {
    SET_MENU_ACTIVE,
    SET_OPEN,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    open: [],
    selected: ['1'],
}

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_MENU_ACTIVE:
            return {
                ...state,
                selected: [action.payload.selected],
                isLoading: true,
            }
        case SET_OPEN:
            return {
                ...state,
                open: action.payload.opened,
                isLoading: true,
            }
        default:
            return state;
    }
}