import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	PlusSquareOutlined,
} from '@ant-design/icons';
import * as _ from 'lodash';
import './ShockSale.scss';

import AddPromotionPeriod from './AddPromotionPeriod';
import PageHeader from '../Shared/PageHeader';
import { getPromotionPeriod } from '../../actions/promotionPeriodActions';
import TableShockSale from './TableShockSale';
import { PromotionType } from '../../constants/enum/promotion-type.enum';

const ShockSale = ({ getPromotionPeriod }) => {
    const [openAdd, setOpenAdd] = useState(false);

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    useEffect(() => {
        const getData = async () => {
            await getPromotionPeriod(PromotionType.SHOCK_SALE);
        }
        getData();
    }, []);

    return (
        <div className="shock-sale">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการโปรโมชัน Shock sale" subTitle="เพิ่ม / ลบ Shock sale" btn={{ 
                        text: 'เพิ่มช่วงเวลา',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableShockSale promotionType={PromotionType.SHOCK_SALE} />
                </Col>
            </Row>
            {openAdd && <AddPromotionPeriod handleOpenAdd={handleOpenAdd} open={openAdd} promotionType={PromotionType.SHOCK_SALE} />}
        </div>
    )
}

ShockSale.propTypes = {
    getPromotionPeriod: PropTypes.func.isRequired,
}

export default connect(null, { getPromotionPeriod })(ShockSale);