import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from "../constants/routes";
import { Provider } from 'react-redux'
import store from '../store';

import PrivateRoute from '../utils/PrivateRoute';
import HomePage from "../pages/home";
import ProductPage from '../pages/product';
import SignInPage from '../pages/signin';
import CategoryPage from '../pages/category';
import SubCategoryPage from '../pages/subcategory';
import OrderPage from '../pages/order';
import CustomerPage from '../pages/customer';
import AdjustStock from '../pages/adjuststock';
import UserPage from '../pages/user';
import BannerPage from '../pages/banner';
import ImportProductPage from '../pages/importproduct';
import ImportCouponPage from '../pages/importcoupon';
import CouponPage from '../pages/coupon';
import HtmlPage from '../pages/html';
import MaintenancePage from '../pages/maintenance';

import { loadUser } from '../actions/authActions';
import ShockSalePage from '../pages/shocksale';
import PromotionMemberPage from '../pages/promotionmember';

store.dispatch(loadUser());

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <PrivateRoute exact path={ROUTES.HOME} component={HomePage} />
                    <PrivateRoute path={ROUTES.PRODUCT} component={ProductPage} />
                    <Route path={ROUTES.SIGNIN} component={SignInPage} />
                    <PrivateRoute path={ROUTES.CATEGORY} component={CategoryPage} />
                    <PrivateRoute path={ROUTES.SUBCATEGORY} component={SubCategoryPage} />
                    <PrivateRoute path={ROUTES.ORDER + "/:type?/"} component={OrderPage} />
                    <PrivateRoute path={ROUTES.CUSTOMER} component={CustomerPage} />
                    <PrivateRoute path={ROUTES.MAINTENANCE} component={MaintenancePage} />
                    <PrivateRoute path={ROUTES.ADJUST_STOCK} component={AdjustStock} />
                    <PrivateRoute path={ROUTES.USER} component={UserPage} />
                    <PrivateRoute path={ROUTES.BANNER} component={BannerPage} />
                    <PrivateRoute path={ROUTES.IMPORT_PRODUCT} component={ImportProductPage} />
                    <PrivateRoute path={ROUTES.IMPORT_COUPON} component={ImportCouponPage} />
                    <PrivateRoute path={ROUTES.COUPON} component={CouponPage} />
                    <PrivateRoute path={ROUTES.HTML + "/:type?/"} component={HtmlPage} />
                    <PrivateRoute path={ROUTES.SHOCK_SALE} component={ShockSalePage} />
                    <PrivateRoute path={ROUTES.PROMOTION_MEMBER} component={PromotionMemberPage} />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
