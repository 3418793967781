import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
	PlusSquareOutlined
} from '@ant-design/icons';

import PageHeader from '../Shared/PageHeader';
import AddSubCategory from './AddSubCategory';
import TableSubCategory from './TableSubCategory';
import FilterForm from './FilterForm';
import ViewSubCategory from './ViewSubCategory';

import { getCategories } from '../../actions/productActions';

const SubCategory = ({ getCategories, category }) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [categorySubName, setCategorySubName] = useState(null);
    const [categorySubCode, setCategorySubCode] = useState(null);
    const [categoryName, setCategorySelected] = useState(null);
    const [categoryCode, setCategoryCodeSelected] = useState(null);

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    const handleOpenView = status => {
        setOpenView(status);
    }

    const updateCategorySelected = v => {
        setCategoryCodeSelected(category.categories.filter(item => item.name === v)[0].code);
        setCategorySelected(v);
    }

    const updateCategorySubName = name => {
        setCategorySubName(name);
    }

    const updateCategorySubCode = code => {
        setCategorySubCode(code);
    }

    useEffect(() => {
        const _getCategory = async () => {
            await getCategories();
        }
        _getCategory();
    }, []);

    const data = !category.isLoading ? category.categories.filter(item => item.name === categoryName) : [];
    return (
        <div className="subcategory">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการหมวดหมู่ย่อย (Sub Category)" subTitle="เพิ่ม / แก้ไข / ลบ หมวดหมู่สินค้าย่อย" btn={{ 
                        text: 'เพิ่มหมวดหมู่ย่อยใหม่',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FilterForm category={category} updateCategorySelected={updateCategorySelected} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableSubCategory
                        data={data}
                        loading={category.isLoading}
                        handleOpenView={handleOpenView}
                        updateCategorySubName={updateCategorySubName}
                        updateCategorySubCode={updateCategorySubCode}
                    />
                </Col>
            </Row>
            {openAdd && <AddSubCategory handleOpenAdd={handleOpenAdd} open={openAdd} />}
            {openView && <ViewSubCategory handleOpenView={handleOpenView} categoryName={categoryName} categoryCode={categoryCode} categorySubName={categorySubName} categorySubCode={categorySubCode} open={openView} />}
        </div>
    )
}

const mapStateToProps = ({ category }) => {
    return { category }
}

SubCategory.propTypes = {
    getCategories: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, { getCategories })(SubCategory);