import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';
import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const createHtml = async htmlOjb => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation setHtml($htmlOjb: HtmlInput!) {
            setHtml(htmlInput: $htmlOjb) {
                _id
                type
                content
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                htmlOjb,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.setHtml) {
            return {
                status: true,
            }
        } else {
            return {
                status: false,
            }
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const getHtml = async type => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    let query = {
        query: `{
            html(type: "${type}") {
                _id
                type
                content
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.html) {
            return {
                status: true,
                data: res.data.html,
            }
        } else {
            return {
                status: false,
                data: null,
            }
        }
    })
    .catch(err => console.log('err', err));
}