import { combineReducers } from 'redux'

import product from './productReducer';
import category from './categoryReducer';
import order from './orderReducer';
import customer from './customerReducer';
import menu from './menuReducer';
import user from './userReducer';
import auth from './authReducer';
import banner from './bannerReducer';
import coupon from './couponReducer';
import setting from './settingReducer';
import promotionPeriod from './promotionPeriodReducer';

export default combineReducers({
    product,
    category,
    order,
    customer,
    menu,
    user,
    auth,
    banner,
    coupon,
    setting,
    promotionPeriod,
});
