import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Checkbox,
	Alert,
} from 'antd';

import UploadForm from '../UploadForm';
import { getBannerById, updateBannerId } from '../../actions/bannerAction';

const ViewBanner = ({ bannerStatus, id, open, handleOpenView, updateBannerId }) => {
	const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [banner, setBanner] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const [newImage, setNewImage] = useState(null);
	const [form] = Form.useForm();

    useEffect(() => {
        const _getBannerById = async id => {
			setIsLoading(true);
			try {
				const _banner = await getBannerById(bannerStatus, id);
				if (_banner.data && _banner.data !== null && _banner.data.getBannerById !== null) {
					setBanner(_banner.data.getBannerById);
				} else {
					setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
				}
			} catch (error) {
				console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
			}
			setIsLoading(false);
		}
		_getBannerById(id);
	}, []);

    const onClose = () => {
        handleOpenView(false);
	}

	const handleSetNewImage = (imageUrl) => {
		setNewImage(imageUrl)
	}

	const onFinish = async values => {
		const isUpdateBanner = await updateBannerId(values, id);
		if (isUpdateBanner.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
		}
    };

	const renderBanner = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}
		console.log(newImage);
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish} 
				initialValues={{
					name: banner.name,
					priority: banner.priority,
					urlLink: banner.urlLink,
					active: banner.active,
				}}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="ชื่อแบนเนอร์"
							rules={[{ required: true, message: 'กรุณากรอกชื่อแบนเนอร์' }]}
						>
						<Input placeholder="กรอกชื่อแบนเนอร์" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="priority"
							label="Priority"
							rules={[{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }]}
						>
						<Input placeholder="กรอก Priority" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="urlLink"
							label="Link URL"
						>
						<Input placeholder="กรอก URL" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item  name="active" valuePropName="checked">
							<Checkbox>ใช้งาน</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12} >
						{banner.urlImage ? <img width="320" src={`${banner.urlImage}`} alt="product image" /> : 'No image'}
					</Col>
					<Col span={12}>
						<UploadForm moduleUpload="banner" id={banner._id} text="อัพโหลดภาพใหม่" afterUpload={handleSetNewImage} />
						<h5><font color="#f55c47">รูปภาพขนาด กว้าง 1900 x สูง 500 (เฉพาะนามสกุล .jpg .jpeg .png เท่านั้น)</font></h5>
					</Col>
				</Row>
				{/* <Row gutter={16} style={{ marginTop: '30px' }}>
					<Col span={12}>
						<UploadForm moduleUpload="banner" id={banner._id} />
					</Col>
				</Row> */}
				<Button style={{marginBottom: '25px', marginTop: '25px' }} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทึกข้อมูล
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}

    return (
        <Drawer
			title="แก้ไขข้อมูลแบนเนอร์"
			width={720}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderBanner()}
			</div>
        </Drawer>
    )
}

ViewBanner.propTypes = {
	updateBannerId: PropTypes.func.isRequired,
}

export default connect(null, { updateBannerId })(ViewBanner);