import React from 'react';
import * as moment from 'moment';
import { 
	Card,
	Descriptions,
	Table,
	Row,
	Col,
	Statistic,
} from 'antd';
import { MONEY_FORMAT } from '../../services/number';

class OrderToPrint extends React.Component {

    renderA() {
        const { order } = this.props;
        const columns = [
			{
				title: 'รหัสสินค้า',
				dataIndex: 'code',
				key: 'code',
			},
			{
                title: 'ชื่อสินค้า',
                dataIndex: 'name',
                key: 'name',
                render: (text, row) => {
                    return `${row.name} ${row.isPromotionShockSale ? '(สินค้า Shock Sale)' : ''}`;
                }
			},
			{
                title: 'จำนวน',
                dataIndex: 'quantity',
                key: 'quantity',
			},
			{
                title: 'ราคา',
                dataIndex: 'price',
                key: 'price',
                render: price => price ? MONEY_FORMAT(price, 2, true) : null,
			},
			{
				title: 'ยอดรวม',
				dataIndex: 'sum',
				key: 'sum',
				render: (text, row) => {
					const sum = parseInt(row.quantity) * parseFloat(row.price)
					return (
						`${MONEY_FORMAT(sum, 2, true)}`
					)
				}
			},
		];
        return (
            <React.Fragment>
                <Row gutter={24}>
                    <Col span={12}>
                    {order.User && (
                        <Card style={{ marginBottom: '20px' }}>
                            <Descriptions title="ข้อมูลลูกค้า" size="small">
                            <Descriptions.Item label="ชื่อ - นามสกุล">{order.User.firstname} {order.User.lastname}</Descriptions.Item>
                            <Descriptions.Item label="เบอร์โทรศัพท์">{order.User.phone && order.User.phone}</Descriptions.Item>
                            <Descriptions.Item label="อีเมล">{order.User.email}</Descriptions.Item>
                            <Descriptions.Item label="วันเดือนปีเกิด">{order.User.birth !== null && moment.unix(order.User.birth/1000).format("MM/DD/YYYY")}</Descriptions.Item>
                            <Descriptions.Item label="เพศ">{order.User.sex && order.User.sex}</Descriptions.Item>
                            <Descriptions.Item label="วันที่สมัคร">{order.User.created !== null && moment(new Date(order.User.created)).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                            <Descriptions.Item label="Ocean Member Card">{order.User.oceanMemberCard ? order.User.oceanMemberCard : '--- ไม่พบข้อมูล ---'}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    )}
                    </Col>
                    <Col span={12}>
                        <Card style={{ marginBottom: '20px' }}>
                            <Descriptions title="ที่อยู่การจัดส่งสินค้า" size="small">
                            <Descriptions.Item label="ชื่อ - นามสกุล">{order.addressShipping.firstname} {order.addressShipping.lastname}</Descriptions.Item>
                            <Descriptions.Item label="เบอร์โทรศัพท์">{order.addressShipping.phone}</Descriptions.Item>
                            <Descriptions.Item label="ที่อยู่">{order.addressShipping.addr1}</Descriptions.Item>
                            <Descriptions.Item label="หมู่บ้าน">{order.addressShipping.village ? order.addressShipping.village : '-'}</Descriptions.Item>
                            <Descriptions.Item label="ตำบล">{order.addressShipping.subDistrict}</Descriptions.Item>
                            <Descriptions.Item label="อำเภอ">{order.addressShipping.district}</Descriptions.Item>
                            <Descriptions.Item label="จังหวัด">{order.addressShipping.province}</Descriptions.Item>
                            <Descriptions.Item label="รหัสไปรษณีย์">{order.addressShipping.zip}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
            
            
            <Card style={{ marginBottom: '20px' }}>
                <Descriptions title="ที่อยู่ออกใบกำกับภาษี" size="small">
                <Descriptions.Item label="ชื่อ - นามสกุล">{order.addressInvoice.firstname} {order.addressInvoice.lastname}</Descriptions.Item>
                <Descriptions.Item label="เบอร์โทรศัพท์">{order.addressInvoice.phone}</Descriptions.Item>
                <Descriptions.Item label="ที่อยู่">{order.addressInvoice.addr1}</Descriptions.Item>
                <Descriptions.Item label="หมู่บ้าน">{order.addressInvoice.village ? order.addressInvoice.village : '-'}</Descriptions.Item>
                <Descriptions.Item label="ตำบล">{order.addressInvoice.subDistrict}</Descriptions.Item>
                <Descriptions.Item label="อำเภอ">{order.addressInvoice.district}</Descriptions.Item>
                <Descriptions.Item label="จังหวัด">{order.addressInvoice.province}</Descriptions.Item>
                <Descriptions.Item label="รหัสไปรษณีย์">{order.addressInvoice.zip}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Table dataSource={order.products.length > 0 ? order.products.map((item, i) => {return { key: item.product, ...item }}) : []} columns={columns} />
            <Card style={{ marginBottom: '20px' }}>
                <Row gutter={16}>
                    <Col /* span={16} */>
                        <Statistic title="วิธีการจัดส่ง" value={order.transport.join(' + ')} />
                    </Col>
                    {/* <Col span={8}>
                        <Statistic title="วิธีการชำระเงิน" value={order.payment}/>
                    </Col> */}
                </Row>
            </Card>
            <Card style={{ marginBottom: '20px' }}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic title="ยอดรวม" value={MONEY_FORMAT(order.Cost.totalProduct, 2, true)} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="ค่าจัดส่ง" value={order.Cost.transfer === 0 ? 'ไม่มีค่าจัดส่ง' : MONEY_FORMAT(order.Cost.transfer, 2, true)} />
                    </Col>
                    <Col span={8}>
                        <Statistic title="ส่วนลด" value={order.Cost.coupon === 0 ? 'ไม่มีส่วนลด' : MONEY_FORMAT(order.Cost.coupon, 2, true)} />
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: '20px' }}>
                    <Col span={8}>
                        <Statistic title="ยอดรวมทั้งหมด" value={MONEY_FORMAT(order.totalPrice, 2, true)} />
                    </Col>
                </Row>
            </Card>
            </React.Fragment>
        )
    }
    render() {
      return (
        <div>
            {this.renderA()}
        </div>
      );
    }
  }

  export default OrderToPrint;