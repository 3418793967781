import React from 'react';
import { connect } from 'react-redux';
import { 
	Table,
	Input,
	Button,
	Tag,
	Badge,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { memberType } from '../../constants/enum';

class TableCustomer extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
		};
    }

	handleTableChange = (pagination, filters, sort, extra) => {
		const pickFilters = _.pickBy(filters, _.identity);
		const { handleTableChange } = this.props;
		handleTableChange(pagination, pickFilters, extra);
	};
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	rowClick = (record) => {
		const { handleOpenView, updateCustomerId } = this.props;
        return {
            onClick: event => {
				handleOpenView(true);
				updateCustomerId(record._id);
            }
        }
    }

	renderMemberState = memberState => {
		let memberStateText = '';
		switch (memberState) {
			case memberType.NOT_MEMBER:
				memberStateText = <Badge status="default">ไม่ได้เป็นสมาชิก</Badge>;
				break;
			case memberType.CHECKING:
				memberStateText = <Badge status="processing"><h5>กำลังตรวจสอบข้อมูล</h5></Badge>;
				break;
			case memberType.NOT_ACTIVATED:
				memberStateText = <Badge status="default">สถานะไม่ถูกใช้งาน</Badge>;
				break;
			case memberType.EXPIRE:
				memberStateText = <Badge status="error">หมดอายุการใช้งาน</Badge>;
				break;
			case memberType.MISTAKEN:
				memberStateText = <Badge status="warning">ระบบไม่พบข้อมูลสมาชิก กรุณาตรวจสอบ</Badge>;
				break;
			case memberType.IS_MEMBER:
				memberStateText = <Badge status="success">ใช้งานปกติ</Badge>;
				break;
		
			default:
				break;
		}
		return (
			<p><b>{memberStateText}</b></p>
		)
	}

	render() {
		const { selectedRowKeys } = this.state;
		const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		const columns = [
			{
				title: 'ชื่อ',
				dataIndex: 'firstname',
				key: 'firstname',
				...this.getColumnSearchProps('firstname'),
            },
            {
				title: 'นามสกุล',
				dataIndex: 'lastname',
				key: 'lastname',
                ...this.getColumnSearchProps('lastname'),
			},
			{
				title: 'เบอร์โทรศัพท์',
				dataIndex: 'phone',
				key: 'phone',
				...this.getColumnSearchProps('phone'),
			},
			{
				title: 'อีเมล',
				dataIndex: 'email',
				key: 'email',
                ...this.getColumnSearchProps('email'),
            },
			{
				title: 'สถานะสมาชิก',
				dataIndex: 'memberState',
				key: 'memberType',
				filters: [
					{
						text: 'ไม่ได้เป็นสมาชิก',
						value: memberType.NOT_MEMBER,
					},
					{
						text: 'กำลังตรวจสอบข้อมูล',
						value: memberType.CHECKING,
					},
					{
						text: 'สถานะไม่ถูกใช้งาน',
						value: memberType.NOT_ACTIVATED,
					},
					{
						text: 'หมดอายุการใช้งาน',
						value: memberType.EXPIRE,
					},
					{
						text: 'ระบบไม่พบข้อมูลสมาชิก กรุณาตรวจสอบ',
						value: memberType.MISTAKEN,
					},
					{
						text: 'ใช้งานปกติ',
						value: memberType.IS_MEMBER,
					},
				],
				render: memberState => memberState ? this.renderMemberState(memberState) : null
            },
            {
				title: 'สถานะ',
				dataIndex: 'active',
				key: 'active',
                filters: [
					{
						text: 'ใช้งาน',
						value: 'active',
					},
					{
						text: 'ปิดการใช้งาน',
						value: 'inactive',
					},
				],
                render: active => active ? <Tag color="#117A65">ใช้งาน</Tag> : <Tag color="#CB4335">ปิดการใช้งาน</Tag>
            },
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>
					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					onRow={this.rowClick}
					loading={loading}
					dataSource={data && data.docs ? data.docs.map((item, i) => {return { key: item._id, ...item }}) : []}
					pagination={pagination}
					onChange={this.handleTableChange}
				/>
			</div>
		);
  	}
}

export default connect(null, {})(TableCustomer);