import fetch from 'isomorphic-fetch';
import { GET_MAINTENANCE_REQUEST, GET_MAINTENANCE_SUCCESS, GET_MAINTENANCE_ERROR, UPDATE_MAINTENANCE_REQUEST, UPDATE_MAINTENANCE_SUCCESS, UPDATE_MAINTENANCE_ERROR } from '../actionTypes';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const getMaintenance = () => async dispatch => {
    dispatch({ type: GET_MAINTENANCE_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            getMaintenanceStatusAll {
                _id
                name
                active
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.getMaintenanceStatusAll) {
            dispatch({
                type: GET_MAINTENANCE_SUCCESS,
                payload: res.data.getMaintenanceStatusAll,
            })
        } else {
            dispatch({
                type: GET_MAINTENANCE_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const setActiveMaintenance = (id) => async dispatch => {
    dispatch({ type: UPDATE_MAINTENANCE_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation setActiveMaintenance($id: String!) {
            setActiveMaintenance(id: $id) {
                _id
                name
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                id,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.setActiveMaintenance) {
            dispatch({
                type: UPDATE_MAINTENANCE_SUCCESS,
                payload: res.data.setActiveMaintenance,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_MAINTENANCE_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}