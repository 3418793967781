import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Alert
} from 'antd';

import CategoryDropdown from '../Product/CategoryDropdown';

import { createCategorySub } from '../../actions/categoryAction';

const AddSubCategory = ({ open, handleOpenAdd, category, createCategorySub }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenAdd();
    }

    const onFinish = async values => {
		values.code = category.categories.filter(item => item.name === values.category)[0].code;
		const isUpdated = await createCategorySub(values);
		if (isUpdated.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isUpdated.message);
		}
    };

    const renderForm = () => {
        if (category.isLoading) {
            return <div>Loading...</div>
        }

		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish}
			>
                <Row gutter={16}>
                    <Col span={24}>
                        <CategoryDropdown updateCategory={e => console.log(e)} category={category.categories.map(item => item.name)} />
                    </Col>
                </Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="categorySubCode"
							label="รหัสหมวดหมู่สินค้าย่อย"
							rules={[
								{ required: true, message: 'กรุณากรอกรหัสหมวดหมู่สินค้าย่อย (Sub Category)' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกรหัสหมวดหมู่สินค้าย่อย (Sub Category)" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="categorySub"
							label="หมวดหมู่สินค้าย่อย"
							rules={[{ required: true, message: 'กรุณากรอกชื่อหมวดหมู่สินค้าย่อย (Sub Category)' }]}
						>
						<Input placeholder="กรอกชื่อหมวดหมู่สินค้าย่อย (Sub Category)" />
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทีก
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}
    
    return (
        <Drawer
			title="เพิ่มหมวดหมู่ย่อย (Sub Category)"
			width={420}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

const mapStateToProps = ({ category }) => {
    return {
        category,
    }
}

AddSubCategory.propTypes = {
	category: PropTypes.object.isRequired,
	createCategorySub: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { createCategorySub })(AddSubCategory);