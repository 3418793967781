import React from 'react';
import { Row, Col, Card } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';

import PageHeader from '../Shared/PageHeader';
import Import from './Import';
import { exportAdjustProduct } from '../../actions/productActions';

const AdjustStock = () => {

    const exportfile = () => {
        exportAdjustProduct();
    }

    return (
        <div className="product">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการสินค้าคงคลัง (Adjust Stock)" subTitle="" btn={{ 
                        text: 'Export File xlsx สำหรับปรับปรุงสินค้าใน Stock',
                        fnc: exportfile,
                        visible: true,
                        icon: <DownloadOutlined />,
                        size: 'middle',
                        type: 'primary'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card>
                        <Import />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default AdjustStock;