import React from 'react';
import {
    Form,
    Select
} from 'antd';

const { Option } = Select;

const CategoryDropdown = ({ category, updateCategory }) => {
    return (
        <Form.Item
            name="category"
            label="หมวดหมู่สินค้า"
            rules={[{ required: true, message: 'กรุณาเลือกหมวดหมู่สินค้า' }]}
        >
            <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={updateCategory} 
                placeholder="กรุณาเลือกหมวดหมู่สินค้า"
            >
                {category.map((item, i) => <Option key={i} value={item}>{item}</Option>)}
            </Select>
        </Form.Item>
    )
}

export default CategoryDropdown;