import React from 'react';
import { 
    Drawer,
} from 'antd';

import UploadForm from '../UploadForm';
import './Banner.scss';

const UploadBannerDrawer = ({ id, onClose, open }) => {
    return (
        <Drawer
			title="เพิ่มแบนเนอร์"
			width={520}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<UploadForm description="รูปภาพขนาด กว้าง 1900 x สูง 500 (เฉพาะนามสกุล .jpg .jpeg .png เท่านั้น)" moduleUpload="banner" id={id} text="อัพโหลดภาพ" />
        </Drawer>
    )
}

export default UploadBannerDrawer;