import fetch from 'isomorphic-fetch';
import cookie from 'js-cookie';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';
import {
    AUTHENTICATE,
    DEAUTHENTICATE,
} from '../actionTypes';

import { ENCODE_DATA, DECODE_DATA } from '../services/storage';

export const loadUser = () => async dispatch => {
    const token = DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)));

    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    let query = {
        query: `{
            checkAuth
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.checkAuth) {
            dispatch(reauthenticate(token));
        } else {
            removeCookie(ENCODE_DATA(STORAGE_TOKEN, false));
            dispatch({ type: DEAUTHENTICATE });
        }
    })
    .catch(err => console.log('err', err));
}

export const signin = credential => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    let query =
        `mutation loginAdmin($credential: LoginInput!) {
            loginAdmin(loginInput: $credential) {
                token
            }
        }`
    return await fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                credential
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null) {
            setCookie(ENCODE_DATA(STORAGE_TOKEN, false), ENCODE_DATA(res.data.loginAdmin.token));
            dispatch({
                type: AUTHENTICATE,
                payload: res.data.loginAdmin.token,
            });
            return {
                status: true,
                message: null
            }
        } else {
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        console.log('err', err);
    });
}

export const signout = () => async dispatch => {
    removeCookie(ENCODE_DATA(STORAGE_TOKEN, false));
    dispatch({type: DEAUTHENTICATE});
    // Redirect
}

export const reauthenticate = token => async dispatch => {
    dispatch({ type: AUTHENTICATE, payload: token });
}


/**
 * cookie helper methods
**/

export const setCookie = (key, value) => {
    if (process.browser) {
        cookie.set(key, value, {
            expires: 1,
            path: '/'
        });
    }
}

export const removeCookie = key => {
    cookie.remove(key, {
        expires: 1
    });
}

export const getCookie = (key) => {
    return cookie.get(key);
}