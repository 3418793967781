import React from 'react';
import { 
    Drawer,
} from 'antd';

import UploadForm from '../UploadForm';
import './Product.scss';

const UploadProductDrawer = ({ productId, onClose, open }) => {
    return (
        <Drawer
			title="เพิ่มรูปสินค้า"
			width={720}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<UploadForm description="รูปภาพขนาด กว้าง 450 x สูง 450 (เฉพาะนามสกุล .jpg .jpeg .png เท่านั้น)" moduleUpload="product" id={productId} text="อัพโหลดภาพสินค้า" />
        </Drawer>
    )
}

export default UploadProductDrawer;