import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { URL_GRAPHQL, STORAGE_TOKEN, API_URL } from '../constants/config';

import {
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_REQUEST,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_ERROR,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_ERROR,
    DELETE_MULTI_PRODUCT_REQUEST,
    DELETE_MULTI_PRODUCT_SUCCESS,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

var FileSaver = require('file-saver');

export const getProductByCategory = (
    code = undefined,
    categoryName = undefined,
    categorySubName = undefined,
    active = undefined,
    limit = undefined,
    like = undefined,
    page = undefined,
    sort = undefined,
    min = undefined,
    max = undefined,
) => async dispatch => {
    dispatch({type: GET_PRODUCT_REQUEST});
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    let selection = ``;
    if (code !== undefined) {
        selection = `code: "${code}",`
    }
    if (categoryName !== undefined) {
        selection = `category: "${categoryName}",`
    }
    if (categorySubName !== undefined) {
        selection += `categorySub: "${categorySubName}",`
    }
    if (limit !== undefined) {
        selection += `limit: ${limit},`
    }
    if (like !== undefined) {
        selection += `like: "${like}",`
    }
    if (page !== undefined) {
        selection += `page: ${page},`
    }
    if (sort !== undefined) {
        selection += `sort: ${sort},`;
    }

    if (min !== undefined) {
        selection += `minPrice: ${min},`;
    }

    if (max !== undefined) {
        selection += `maxPrice: ${max},`;
    }

    if (active !== undefined) {
        selection += `active: ${active},`;
    }

    let query = {
        query: `{
            productsPage(${selection}) {
                docs{
                    _id
                    name
                    category
                    categorySub
                    price
                    specialPrice
                    balance
                    active
                }
                totalDocs
                page
            }
        }`
    }

    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.productsPage) {
            dispatch({
                type: GET_PRODUCT_BY_CATEGORY,
                payload: res.data.productsPage,
            })
        } else {
            dispatch({
                type: GET_PRODUCT_BY_CATEGORY_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const getProductById = async (status, id) => {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    
    let query = {
        query: `{
            getProductById(active: ${status}, id: "${id}") {
                _id
                code
                name
                detail
                urlImage
                category
                categorySub
                price
                specialPrice
                balance
                weight
                isPromotion
                isRecommend
                active
            }
        }`
    }
    
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => res)
    .catch(err => console.log('err', err));
}

export const getCategories = () => async dispatch => {
    dispatch({ type: GET_CATEGORY_REQUEST });
    let query = {
        query: `{
            categories {
                code
                name
                status
                sub {
                    code
                    name
                    status
                }
            }
        }`
    } 

    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null) {
            dispatch({ 
                type: GET_CATEGORY_SUCCESS,
                payload: res.data.categories,
            });     
        }
    })
    .catch(err => console.log('err', err));
}

export const createProduct = productObj => async dispatch => {
    dispatch({ type: CREATE_PRODUCT_REQUEST });
    productObj.price = Number(productObj.price);
    productObj.specialPrice = Number(productObj.specialPrice);
    productObj.balance = Number(productObj.balance);
    productObj.weight = Number(productObj.weight);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation createProduct($productObj: ProductInput!) {
            createProduct(productInput: $productObj) {
                _id
                code
                name
                category
                categorySub
                price
                specialPrice
                balance
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                productObj,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null) {
            dispatch({
                type: CREATE_PRODUCT_SUCCESS,
                payload: res.data,
            });
            return {
                status: true,
                id: res.data.createProduct._id,
            };
        } else {
            dispatch({
                type: CREATE_PRODUCT_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => {
        dispatch({
            type: CREATE_PRODUCT_ERROR,
        });
        console.log('err', err);
        return false;
    });
}

export const updateProductById = (productObj, id) => async dispatch => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    productObj.price = Number(productObj.price);
    productObj.specialPrice = Number(productObj.specialPrice);
    productObj.balance = Number(productObj.balance);
    productObj.weight = Number(productObj.weight);
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateProductById($productObj: ProductInput!, $id: String!) {
            updateProductById(productInput: $productObj, id: $id) {
                _id
                name
                category
                categorySub
                price
                specialPrice
                balance
                active
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                productObj,
                id
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null) {
            dispatch({
                type: UPDATE_PRODUCT_SUCCESS,
                payload: res.data,
            });
            return true;
        } else {
            return false;
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const moveCategoryMultipleProduct = (idArr, category, categorySub) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation moveProductCategory($categorySub: String!, $category: String!, $idArr: [String!]!) {
            moveProductCategory(category: $category, categorySub: $categorySub, id: $idArr) {
                _id
                name
                category
                categorySub
                price
                specialPrice
                balance
                active
            }
        }`
        return fetch(URL_GRAPHQL, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                query,
                variables: {
                    categorySub,
                    category,
                    idArr,
                }
            })
        })
        .then(res => res.json())
        .then(res => {
            if (res.data && res.data !== null && res.data.moveProductCategory) {
                dispatch({
                    type: DELETE_MULTI_PRODUCT_SUCCESS,
                    payload: idArr,
                });
                return true;
            } else {
                return false;
            }
        })
        .catch(err => {
            console.log('err', err);
            return false;
        });
}

export const deleteMultipleProduct = idArr => async dispatch => {
    dispatch({ type: DELETE_MULTI_PRODUCT_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation deleteMultipleProduct($idArr: [String!]!) {
            deleteMultipleProduct(id: $idArr)
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                idArr
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.deleteMultipleProduct) {
            dispatch({
                type: DELETE_MULTI_PRODUCT_SUCCESS,
                payload: idArr,
            });
            return true;
        } else {
            return false;
        }
    })
    .catch(err => {
        console.log('err', err);
        return false;
    });
}

export const exportAdjustProduct = () => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    axios.get(
        `${API_URL}/product/exportAdjust`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'adjust_stock.xlsx');
        });
}

export const exportProduct = () => {
    const headers = {
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
    }
    axios.get(
        `${API_URL}/product/export`,
        { 
            responseType: 'arraybuffer', 
            headers 
        })
        .then((response) => {
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'product.xlsx');
        });
}