import React, { useState, useEffect } from 'react';
import { 
    Form,
    Button,
    Card,
    Select,
    Row,
    Col
} from 'antd';
const { Option } = Select;

const FilterForm = ({ category, updateCategorySelected }) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = async values => {
        updateCategorySelected(values.category)
    };

    const renderCategory = () => {
        const { isLoading, categories } = category;
        if (isLoading) {
            return <Option value="" key="0">Loading...</Option>;
        }

        return categories.map((item, i) => <Option key={i} value={item.name}>{item.name}</Option>)
    }

    return (
        <Card>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{
					active: "true"
				}}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="category"
                            rules={[{ required: true, message: 'กรุณาเลือกหมวดหมู่สินค้า' }]}
                        >
                            <Select 
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="กรุณาเลือกหมวดหมู่สินค้า"
                                onChange={updateCategorySelected}
                            >
                                {renderCategory()}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default FilterForm;