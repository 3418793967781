import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { 
	Table,
	Input,
	Button,
	Tag,
	Popconfirm,
} from 'antd';
import * as _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

import ChangeStateOrder from './ChangeStateOrder';
import { MONEY_FORMAT } from '../../services/number';

import { changeStateOrders } from '../../actions/orderActions';

class TableOrder extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
			changeStateLoading: false,
			modalVisible: false,
		};
	}

	handleTableChange = (pagination, filters, sort, extra) => {
		const pickFilters = _.pickBy(filters, _.identity);
		if (pickFilters.orderNumber) {
			pickFilters.orderNumber = +pickFilters.orderNumber;
		}
		if (pickFilters.totalPrice) {
			pickFilters.totalPrice = +pickFilters.totalPrice;
		}
		const { handleTableChange } = this.props;
		handleTableChange(pagination, pickFilters, extra);	
	};

	showModal = () => {
		this.setState({ modalVisible: true });
	}

	handleCancel = () => {
		this.setState({ modalVisible: false });
	};

	clearSelected = () => {
		this.setState({ selectedRowKeys: [] });
	}

	cancel = async () => {
		this.setState({ changeStateLoading: true });
		const { selectedRowKeys } = this.state;
		const { changeStateOrders } = this.props;
		const isUpdated = await changeStateOrders({ids: selectedRowKeys, newState: 'CANCEL_BY_ADMIN'});
		
		if (isUpdated.status) {
			setTimeout(() => {
				this.setState({
					selectedRowKeys: [],
					changeStateLoading: false,
				});
			}, 1000);
		} else {
			alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
		}
	}
	
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	rowClick = (record) => {
		const { handleOpenView, updateOrderId } = this.props;
        return {
            onClick: event => {
				handleOpenView(true);
				updateOrderId(record._id);
            }
        }
    }

	render() {
		const { changeStateLoading, selectedRowKeys, modalVisible } = this.state;
		const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		const columns = [
			{
				title: 'เลขที่สั่งซื้อ',
				dataIndex: 'orderNumber',
				key: 'orderNumber',
				...this.getColumnSearchProps('orderNumber'),
            },
            {
				title: 'วันที่สั่งซื้อ',
				dataIndex: 'created',
				key: 'created',
                ...this.getColumnSearchProps('created'),
                render: created => created ? moment(created).format('DD/MM/YYYY HH:mm:ss') : null
			},
			{
				title: 'ลูกค้า',
				dataIndex: 'User',
				key: 'customerName',
				...this.getColumnSearchProps('customerName'),
				render: User => User && `${User.firstname} ${User.lastname}`
			},
			{
				title: 'รวมเงิน',
				dataIndex: 'totalPrice',
				key: 'totalPrice',
                ...this.getColumnSearchProps('totalPrice'),
                render: price => price ? MONEY_FORMAT(price, 2, true) : null
            },
            {
				title: 'วิธีการขนส่ง',
				dataIndex: 'transport',
				key: 'transport',
				...this.getColumnSearchProps('transport'),
                render: transport => {
					const count = transport.length


					if(count===1) 
						return transport.pop()

					if(count>1) {

						if(transport[0].search("EMS")>-1) 
							return `EMS x${count}`

						if(transport[0].search("Normal")>-1) 
							return `Normal x${count}`

					}

					// if(count<1) 
					return transport

				}
            },
			{
				title: 'สถานะ',
				dataIndex: 'state',
				key: 'state',
				filters: [
					{
						text: 'รอชำระเงิน',
						value: 'PAYMENT',
					},
					{
						text: 'เตรียมจัดส่ง',
						value: 'PREPARE_PRODUCT',
					},
					{
						text: 'จัดส่งแล้ว',
						value: 'DELIVERY',
					},
					{
						text: 'ยกเลิกโดย Admin',
						value: 'CANCEL_BY_ADMIN',
					},
					{
						text: 'ไม่ชำระเงินเกินเวลาที่กำหนด',
						value: 'CANCEL_BY_SYSTEM',
					},
				],
				render: state => {
					let transform;
					switch (state) {
						case 'PAYMENT':
							transform = <Tag color="#EB984E">รอชำระเงิน</Tag>
							break;
						case 'PREPARE_PRODUCT':
							transform = <Tag color="#F1C40F">เตรียมจัดส่ง</Tag>
							break;
						case 'DELIVERY':
							transform = <Tag color="#117A65">จัดส่งแล้ว</Tag>
							break;
						case 'CANCEL_BY_ADMIN':
							transform = <Tag color="#CB4335">ยกเลิกโดย Admin</Tag>
							break;
						case 'CANCEL_BY_SYSTEM':
							transform = <Tag color="#CB4335">ไม่ชำระเงินเกินเวลาที่กำหนด</Tag>
							break;
						default:
							break;
					}
					return transform;
				}
			}
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>

					<Popconfirm
						title="ยืนยันที่จะยกเลิกรายการสั่งซื้อเหล่านี้?"
						onConfirm={this.cancel}
						okText="ใช่"
						cancelText="ไม่"
					>
						<Button style={{ marginRight: '25px' }} type="primary" disabled={!hasSelected} loading={changeStateLoading}>
							ยกเลิกรายการสินค้า
						</Button>
					</Popconfirm>
					
					<Button type="primary" onClick={this.showModal} disabled={!hasSelected}>
						เปลี่ยนสถานะ
					</Button>
					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `เลือกไว้ ${selectedRowKeys.length} รายการ` : ''}
					</span>
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					onRow={this.rowClick}
					loading={loading}
					dataSource={data && data.docs ? data.docs.sort((a, b) => b.orderNumber - a.orderNumber).map((item, i) => {return { key: item._id, ...item }}) : []}
					pagination={pagination}
					onChange={this.handleTableChange}
				/>
				<ChangeStateOrder clearSelected={this.clearSelected} selectedRowKeys={selectedRowKeys} handleCancel={this.handleCancel} modalVisible={modalVisible} />
			</div>
		);
  	}
}

TableOrder.propTypes = {
	changeStateOrders: PropTypes.func.isRequired,
}

export default connect(null, { changeStateOrders })(TableOrder);