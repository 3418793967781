import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

import SubCategory from '../components/SubCategory';

const SubCategoryPage = () => {
    const { Content } = Layout;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <SubCategory />
            </Content>
        </MainLayout>
    )
}

export default SubCategoryPage;