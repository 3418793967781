import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Form,
    Button,
    Modal,
    Select,
    Row,
    Col
} from 'antd';

import { moveCategoryMultipleProduct } from '../../actions/productActions';

const { Option } = Select;

const MoveCategory = ({ clearSelected, category, handleCancel, modalVisible, selectedRowKeys, moveCategoryMultipleProduct }) => {
    const [, forceUpdate] = useState();
    const [categorySelected, setCategorySelected] = useState('');
    const [subCategorySelected, setSubCategorySelected] = useState('');
    const [moveLoading, setMoveLoading] = useState(false);
    const [subCategory, setSubCategory] = useState([]);

    useEffect(() => {
        forceUpdate({});
    }, []);

	const handleOk = async () => {
        setMoveLoading(true);
        const isMoved = await moveCategoryMultipleProduct(selectedRowKeys, categorySelected, subCategorySelected);
        if (isMoved) {
            setTimeout(() => {
                clearSelected();
                setMoveLoading(false);
                closeModal();
            }, 1500);
        } else {
            alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
        }
    };

    const closeModal = () => {
        handleCancel();
        setCategorySelected('');
        setSubCategorySelected('');
    }

    const renderCategory = () => {
        const { isLoading, categories } = category;
        if (isLoading) {
            return <Option value="" key="99">Loading...</Option>;
        }

        return categories.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>)
    }

    const updateCategory = v => {
        const { categories } = category;
        setCategorySelected(v);
        setSubCategorySelected('');
        const [{sub}] = categories.filter(item => item.name === v)
        setSubCategory(sub);
    }
    
    const updateSubCategory = v => {
        setSubCategorySelected(v);
    }

    return (
        <Modal
            visible={modalVisible}
            title="ย้ายหมวดหมู่สินค้า"
            onOk={handleOk}
            onCancel={closeModal}
            footer={[
                <Button key="back" onClick={closeModal}>
                    ยกเลิก
                </Button>,
                <Button key="submit" disabled={categorySelected === null || subCategorySelected === "" ? true : false} type="primary" loading={moveLoading} onClick={handleOk}>
                    ยืนยันการย้ายหมวดหมู่
                </Button>,
            ]}
            >
            <Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item>
                        <Select 
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={categorySelected}
                            onChange={updateCategory} 
                            placeholder="กรุณาเลือกหมวดหมู่สินค้า"
                        >
                            <Option value="" key="0">เลือกหมวดหมู่สินค้าย่อย</Option>
                            {renderCategory()}
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                        <Select 
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={subCategorySelected} 
                            onChange={updateSubCategory} 
                            placeholder="กรุณาเลือกหมวดหมู่สินค้าย่อย"
                        >
                            <Option value="" key="0">เลือกหมวดหมู่สินค้าย่อย</Option>
                            {subCategory.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>)}
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const mapStateToProps = ({ category }) => {
    return { category };
}

MoveCategory.propTypes = {
    category: PropTypes.object.isRequired,
    moveCategoryMultipleProduct: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { moveCategoryMultipleProduct })(MoveCategory);