import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	PlusSquareOutlined,
} from '@ant-design/icons';
import * as _ from 'lodash';
import './PromotionMember.scss';

import PageHeader from '../Shared/PageHeader';
import { getPromotionPeriod } from '../../actions/promotionPeriodActions';
import TableShockSale from '../ShockSale/TableShockSale';
import AddPromotionPeriod from '../ShockSale/AddPromotionPeriod';
import { PromotionType } from '../../constants/enum/promotion-type.enum';

const PromotionMember = ({ getPromotionPeriod }) => {
    const [openAdd, setOpenAdd] = useState(false);

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    useEffect(() => {
        const getData = async () => {
            await getPromotionPeriod(PromotionType.MEMBER);
        }
        getData();
    }, []);

    return (
        <div className="promotion-member">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการโปรโมชัน ราคาสมาชิก" subTitle="เพิ่ม / ลบ Promotion member" btn={{ 
                        text: 'เพิ่มช่วงเวลา',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableShockSale promotionType={PromotionType.MEMBER} />
                </Col>
            </Row>
            {openAdd && <AddPromotionPeriod handleOpenAdd={handleOpenAdd} open={openAdd} promotionType={PromotionType.MEMBER} />}
        </div>
    )
}

PromotionMember.propTypes = {
    getPromotionPeriod: PropTypes.func.isRequired,
}

export default connect(null, { getPromotionPeriod })(PromotionMember);