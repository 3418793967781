import {
    GET_COUPON_REQUEST,
    GET_COUPON_SUCCESS,
    GET_COUPON_ERROR,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    coupons: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    coupon: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_COUPON_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_COUPON_SUCCESS:
            return {
                ...state,
                coupons: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case GET_COUPON_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}