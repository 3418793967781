import {  GET_MAINTENANCE_SUCCESS, UPDATE_MAINTENANCE_SUCCESS } from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    setting: {
        maintenances: [],
        maintenance: {}
    },
}

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    maintenances: action.payload,
                },
                isLoading: false,
            }
        case UPDATE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    maintenances: state.setting.maintenances.map(maintenance => {
                        if (maintenance._id === action.payload._id) {
                            return action.payload;
                        } else {
                            return {...maintenance}
                        }
                    }),
                },
                isLoading: false,
            }
        default:
            return state;
    }
}