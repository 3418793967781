import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Form, 
    Input,
    Button,
    Checkbox,
} from 'antd';
import { Link } from 'react-router-dom';

import './Form.scss';
import { STATIC_IMG_PATH } from '../../constants/config';

import { signin } from '../../actions/authActions';

const FormSignIn = ({ signin }) => {
    const [isError, setIsError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const onFinish = async values => {
        delete values.remember;
        setSubmitLoading(true);
        setIsError(false);
        setErrMessage('');
        const isSignIn = await signin(values);
        
        if (!isSignIn.status) {
            setIsError(true);
            setSubmitLoading(false);
            setErrMessage(isSignIn.message);
        }
    };

    return (
        <Form
            layout="vertical" 
			hideRequiredMark 
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <div className="logo-login">
                <img src={STATIC_IMG_PATH+'logo-big-one.png'} width="150pt" alt="logo-big-one" />
            </div>
            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input
                    size="large"
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item className="login-form-remember" name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link to="" className="login-form-forgot">
                    Forgot password?
                </Link>
            </Form.Item>

            <Form.Item>
                <Button loading={submitLoading} type="primary" htmlType="submit" className="login-form-button">
                เข้าสู่ระบบ
                </Button>
                {isError && <div style={{ textAlign: 'center', color: 'red' }}>{errMessage}</div>}
            </Form.Item>
        </Form>
    )
}

FormSignIn.propTypes = {
    signin: PropTypes.func.isRequired,
}

export default connect(null, { signin })(FormSignIn);