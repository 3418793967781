import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Table,
	Input,
    Button,
    Tag,
} from 'antd';
import * as _ from 'lodash';
import { SearchOutlined } from '@ant-design/icons';

import { deleteMultipleBanner } from '../../actions/bannerAction';

class TableBanner extends React.Component {
	constructor() {
		super();
		this.state = {
			selectedRowKeys: [],
			deleteLoading: false,
		};
    }
	
	handleTableChange = (pagination, filters, sort, extra) => {
		const pickFilters = _.pickBy(filters, _.identity);
		const { handleTableChange } = this.props;
		handleTableChange(pagination, pickFilters, extra);
	};

	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
	});

	delete = async () => {
		this.setState({ deleteLoading: true });
		const { selectedRowKeys } = this.state;
		const { deleteMultipleBanner } = this.props;
		const isDeleted = await deleteMultipleBanner(selectedRowKeys);
		
		if (isDeleted.status) {
			setTimeout(() => {
				this.setState({
					selectedRowKeys: [],
					deleteLoading: false,
				});
			}, 1000);
		} else {
			alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
		}
	}

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
	};

	handleReset = clearFilters => {
		clearFilters();
	};

	rowClick = (record) => {
		const { handleOpenView, updateBannerId, updateBannerStatus } = this.props;
        return {
            onClick: event => {
				handleOpenView(true);
				updateBannerId(record._id);
				updateBannerStatus(record.active);
            }
        }
    }

	render() {
		const { selectedRowKeys, deleteLoading } = this.state;
		const { data, loading, pagination } = this.props;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		const hasSelected = selectedRowKeys.length > 0;
		const columns = [
			{
				title: 'ชื่อ',
				dataIndex: 'name',
				key: 'name',
				...this.getColumnSearchProps('name'),
            },
            {
				title: 'url',
				dataIndex: 'urlLink',
				key: 'urlLink',
                ...this.getColumnSearchProps('urlLink'),
			},
			{
				title: 'ลำดับ',
				dataIndex: 'priority',
				key: 'priority',
			},
            {
				title: 'สถานะ',
				dataIndex: 'active',
				key: 'active',
                filters: [
					{
						text: 'ใช้งาน',
						value: 'active',
					},
					{
						text: 'ปิดการใช้งาน',
						value: 'inactive',
					},
				],
                render: active => active ? <Tag color="#117A65">ใช้งาน</Tag> : <Tag color="#CB4335">ปิดการใช้งาน</Tag>
            },
		];
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 16, marginLeft: 25 }}>
					<Button style={{ marginRight: '25px' }} type="primary" onClick={this.delete} disabled={!hasSelected} loading={deleteLoading}>
						ลบข้อมูล
					</Button>
					<span style={{ marginLeft: 8 }}>
						{hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
					</span>
				</div>
				<Table
					columns={columns}
					rowSelection={rowSelection}
					onRow={this.rowClick}
					loading={loading}
					dataSource={data && data.docs ? data.docs.map((item, i) => {return { key: item._id, ...item }}) : []}
					pagination={pagination}
					onChange={this.handleTableChange}
				/>
			</div>
		);
  	}
}

TableBanner.propTypes = {
	deleteMultipleBanner: PropTypes.func.isRequired,
}

export default connect(null, { deleteMultipleBanner })(TableBanner);