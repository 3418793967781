import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Row,
    Col,
    Select,
	Drawer,
	Card,
	Descriptions,
	Alert,
	Form,
	Checkbox,
	Button,
	Input,
	Badge,
} from 'antd';
import {
	CheckCircleTwoTone,
	CloseCircleTwoTone,
	ShoppingCartOutlined,
	AuditOutlined,
} from '@ant-design/icons';
import * as moment from 'moment';
import { memberType } from '../../constants/enum';

import { getCustomerById, updateProfileByAdmin } from '../../actions/customerAction';

const ViewCustomer = ({ id, open, handleOpenView, updateProfileByAdmin }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [customer, setCustomer] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const [address, setAddress] = useState(null);
	const [isCompleted, setIsCompleted] = useState(null);
	const [formProfile] = Form.useForm();

    useEffect(() => {
        const _getCustomerById = async id => {
			setIsLoading(true);
			try {
				const _customer = await getCustomerById(id);
				if (_customer.data && _customer.data !== null && _customer.data.user !== null) {
					const data = _customer.data.user;
					setCustomer(data);
					if (data.addresses.length > 0) {
						setAddress(data.addresses[0]);
					}
				} else {
					setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
				}	
			} catch (error) {
				console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
			}
			setIsLoading(false);
		}
		_getCustomerById(id);
	}, []);

    const onClose = () => {
        handleOpenView(false);
	}
	
	const onFinishProfile = async values => {
		const isUpdateCustomer = await updateProfileByAdmin(id, values);
		if (isUpdateCustomer.status) {
			setIsError(false);
			setIsCompleted(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsCompleted(false);
			setErrMessage(isUpdateCustomer.message);
		}
	};

	const renderCustomer = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}

		const handleSelectedAddress = v => {
			if (v !== '') {
				setAddress(customer.addresses[v]);
			}
		}
		
		const renderOptionAddress = () => {
			let option;
			if (customer.addresses.length > 0) {
				option = [<Select.Option key="0" value="">--- กรุณาเลือกที่อยู่ ---</Select.Option>];
				customer.addresses.forEach((item, i) => option.push(<Select.Option key={i+1} value={i}>{item.addr1} {item.village} {item.subDistrict} {item.district} {item.province} {item.isShipping ? <ShoppingCartOutlined /> : null} {item.isInvoice ? <AuditOutlined /> : null}</Select.Option>));
			} else {

			}
			
			return option;
		}

		const renderMemberState = () => {
			let memberStateText = '';
			switch (customer.memberState) {
				case memberType.NOT_MEMBER:
					memberStateText = <Badge status="default">ไม่ได้เป็นสมาชิก</Badge>;
					break;
				case memberType.CHECKING:
					memberStateText = <Badge status="processing"><h5>กำลังตรวจสอบข้อมูล</h5></Badge>;
					break;
				case memberType.NOT_ACTIVATED:
					memberStateText = <Badge status="default">สถานะไม่ถูกใช้งาน</Badge>;
					break;
				case memberType.EXPIRE:
					memberStateText = <Badge status="error">หมดอายุการใช้งาน</Badge>;
					break;
				case memberType.MISTAKEN:
					memberStateText = <Badge status="warning">ระบบไม่พบข้อมูลสมาชิก</Badge>;
					break;
				case memberType.IS_MEMBER:
					memberStateText = <Badge status="success">ใช้งานปกติ</Badge>;
					break;
			
				default:
					break;
			}
			return (
				<p><b>{memberStateText}</b></p>
			)
		}

		return (
			<React.Fragment>
				<Card style={{ marginBottom: '20px' }}>
					<Descriptions title="ข้อมูลเบื้องต้น" size="small">
					<Descriptions.Item label="ชื่อ - นามสกุล">{customer.firstname} {customer.lastname}</Descriptions.Item>
					<Descriptions.Item label="เบอร์โทรศัพท์">{customer.phone && customer.phone}</Descriptions.Item>
					<Descriptions.Item label="อีเมล">{customer.email}</Descriptions.Item>
					<Descriptions.Item label="วันเดือนปีเกิด">{customer.birth !== null && moment.unix(customer.birth/1000).format("MM/DD/YYYY")}</Descriptions.Item>
					<Descriptions.Item label="เพศ">{customer.sex && customer.sex}</Descriptions.Item>
					<Descriptions.Item label="วันที่สมัคร">{customer.created !== null && moment(new Date(customer.created)).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
					<Descriptions.Item label="สถานะสมาชิก">{customer.memberState != null ? renderMemberState() : '-'}</Descriptions.Item>
					</Descriptions>
				</Card>
                <Card title="ข้อมูลที่อยู่" headStyle={{ border: 0 }} style={{ marginBottom: '20px' }}>
					<Row gutter={16}>
						<Col span={24}>
							<Select 
								defaultValue="" 
								size="large" 
								style={{ width: '700px' }} 
								onChange={handleSelectedAddress}
							>
								{renderOptionAddress()}
							</Select>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							
						</Col>
					</Row>
				</Card>
				{address !== null ? (
					<Card bordered={false} style={{ marginBottom: '20px' }}>
						<Descriptions size="small">
						<Descriptions.Item label="ชื่อสกุล">{address.firstname} {address.lastname}</Descriptions.Item>
						<Descriptions.Item label="เบอร์โทรศัพท์">{address.phone}</Descriptions.Item>
						<Descriptions.Item label="ที่อยู่">{address.addr1}</Descriptions.Item>
						<Descriptions.Item label="หมู่บ้าน">{address.village ? address.village : '-'}</Descriptions.Item>
						<Descriptions.Item label="ตำบล">{address.subDistrict}</Descriptions.Item>
						<Descriptions.Item label="อำเภอ">{address.district}</Descriptions.Item>
						<Descriptions.Item label="จังหวัด">{address.province}</Descriptions.Item>
						<Descriptions.Item label="รหัสไปรษณีย์">{address.zip}</Descriptions.Item>
						<Descriptions.Item label="ใช้เป็นที่จัดส่งสินค้า">{address.isShipping ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</Descriptions.Item>
						<Descriptions.Item label="ใช้เป็นที่จัดส่งใบกำกับภาษี">{address.isInvoice ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />}</Descriptions.Item>
						</Descriptions>
					</Card>
				) : null}
				<Card title="แก้ไขข้อมูลลูกค้า" headStyle={{ color: '#cc1c1c', border: 0 }} style={{ marginBottom: '20px', borderColor: '#cc1c1c' }}>
					<Form
						form={formProfile}
						layout="vertical" 
						hideRequiredMark 
						onFinish={onFinishProfile}
						initialValues={{
							active: customer.active,
							oceanMemberCard: customer.oceanMemberCard,
							citizenId: customer.citizenId,
							phone: customer.phone,
						}}
						>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="oceanMemberCard"
										label="Ocean Member Card"
										rules={[{ required: false, max: 10 }]}
									>
										<Input placeholder="รหัส Ocean Member Card" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="citizenId"
										label="Citizen ID"
										rules={[{ required: false, max: 13 }]}
									>
										<Input placeholder="รหัสบัตรประชาชน / Citizen ID" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name="phone"
										label="เบอร์โทรศัพท์"
										rules={[
											{
												required: false,
											  	pattern: new RegExp(/^\d{9,10}$/),
											  	message: "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"
											}
										]}
									>
										<Input placeholder="เบอร์โทรศัพท์ / Phone" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item 
										name="active"
										valuePropName="checked"
										label="สถานะ"
									>
										<Checkbox>เปิดใช้งาน (Active)</Checkbox>
									</Form.Item>
								</Col>
							</Row>
							
							<Button disabled={isDisableBtn} style={{marginBottom: '25px'}} type="primary" htmlType="submit">
								บันทึกข้อมูล
							</Button>
							{isCompleted ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
							{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
					</Form>
				</Card>
			</React.Fragment>
		)
	}

    return (
        <Drawer
			title="ข้อมูลลูกค้า"
			width={800}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderCustomer()}
			</div>
        </Drawer>
    )
}

ViewCustomer.propTypes = {
	updateProfileByAdmin: PropTypes.func.isRequired,
}

export default connect(null, { updateProfileByAdmin })(ViewCustomer);