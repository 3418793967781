import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    users: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    user: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case CREATE_USER_REQUEST:
        case GET_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    docs: [action.payload, ...state.users.docs],
                    totalDocs: state.users.totalDocs + 1,
                },
                pagination: {
                    ...state.pagination,
                    total: state.users.totalDocs + 1,
                },
                isLoading: false,
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                users: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    docs: state.users.docs.map(user => {
                        if (user._id === action.payload._id) {
                            return action.payload;
                        } else {
                            return {...user}
                        }
                    }),
                },
                isLoading: false,
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    docs: state.users.docs.filter(item => !action.payload.includes(item._id)),
                    totalDocs: state.users.totalDocs - action.payload.length,
                },
                pagination: {
                    ...state.pagination,
                    total: state.users.totalDocs - action.payload.length,
                },
                isLoading: false,
            }
        case CREATE_USER_ERROR:
        case GET_USER_ERROR:
        case UPDATE_USER_ERROR:
        case DELETE_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        default:
            return state;
    }
}