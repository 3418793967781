import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Table,
    Input,
    Button,
	Modal,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { 
    SearchOutlined,
	ExclamationCircleOutlined,
} from '@ant-design/icons';
import ModalImport from './ModalImport';
import moment  from 'moment';
import { exportWithData, deletePromotionPeriod } from '../../actions/promotionPeriodActions';
const { confirm } = Modal;

class TableShockSale extends React.Component {
	constructor() {
		super();
		this.state = {
			searchText: '',
			searchedColumn: '',
            modalVisible: false,
            rowId: '',
		};
    }

    handleExportData = periodId => {
        exportWithData(periodId, this.props.promotionType);
    }

    showModal = periodId => {
		this.setState({
            modalVisible: true,
            rowId: periodId,
        });
	}

    handleCancel = () => {
		this.setState({
            modalVisible: false,
            rowId: '',
        });
	};

	getColumnSearchProps = dataIndex => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
				<Input
				ref={node => {
					this.searchInput = node;
				}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					key="0"
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button key="1" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
		),
		filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		onFilter: (value, record) =>
		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: text =>
		this.state.searchedColumn === dataIndex ? (
			<Highlighter
				highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
				searchWords={[this.state.searchText]}
				autoEscape
				textToHighlight={text.toString()}
			/>
		) : (
			text
		),
	});

	handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	deleteError = () => {
		Modal.error({
			title: 'การลบข้อมูลผิดพลาด',
			content: 'ไม่สามารถลบ ช่วงเวลาได้',
		});
	}

    handleDelete = (id) => {
		const { deletePromotionPeriod } = this.props;
		const b = this.deleteError;
        confirm({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
            icon: <ExclamationCircleOutlined />,
            okText: 'ใช่',
            okType: 'danger',
            cancelText: 'ไม่',
            async onOk() {
				const isDeleted = await deletePromotionPeriod(id);
				if (!isDeleted.status) {
					b();
				}
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

	render() {
        const { modalVisible, rowId } = this.state;
        const { promotionPeriod } = this.props;

		const columns = [
            {
				title: 'วันที่ เวลา เริ่มต้น',
				dataIndex: 'startDate',
				key: 'startDate',
				...this.getColumnSearchProps('startDate'),
                render: startDate => startDate ? moment.utc(startDate).format('DD/MM/YYYY HH:mm:ss') : null,
			},
			{
				title: 'วันที่ เวลา สิ้นสุด',
				dataIndex: 'endDate',
                key: 'endDate',
				...this.getColumnSearchProps('endDate'),
                render: endDate => endDate ? moment.utc(endDate).format('DD/MM/YYYY HH:mm:ss') : null,
			},
			{
				title: 'จำนวนสินค้า (รายการ)',
				dataIndex: 'pages',
                key: 'pages',
                render: pages => pages.totalDocs,
			},
            {
				title: 'Action',
                key: 'active',
                render: (text, record) =>  (
                    <React.Fragment>
                        {record.pages.totalDocs <= 0 ? <Button style={{ marginRight: '20px' }} onClick={e => this.showModal(record._id)}>นำเข้าข้อมูล</Button> : ''}
                        {record.pages.totalDocs > 0 ? <Button style={{ marginRight: '20px' }} onClick={e => this.handleExportData(record._id)} >Export ข้อมูล</Button> : ''}
                        <Button type="dashed" onClick={e => this.handleDelete(record._id)} danger>ลบข้อมูล</Button>
                    </React.Fragment>
                ),
			},
		];
		return (
            <>
                <Table
                    columns={columns}
                    loading={promotionPeriod.isLoading}
                    dataSource={promotionPeriod.promotionPeriods ? promotionPeriod.promotionPeriods.map((item, i) => {return { key: i, ...item }}) : []}
                />
                <ModalImport
                    handleCancel={this.handleCancel}
                    modalVisible={modalVisible}
                    rowId={rowId}
					promotionType={this.props.promotionType}
                />
            </>
        )
  	}
}

const mapStateToProps = ({ promotionPeriod }) => {
    return { promotionPeriod }
}

TableShockSale.propTypes = {
	promotionPeriod: PropTypes.object.isRequired,
	deletePromotionPeriod: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { deletePromotionPeriod })(TableShockSale);