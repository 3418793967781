import React from 'react';
import { PageHeader } from 'antd'

const PageHeaders = () => {
    return (
        <PageHeader
            // onBack={() => null}
            title="ระบบจัดการรายการสั่งซื้อ (Order)"
            extra={[
                
            ]}
        />
    );
}

export default PageHeaders;