import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Checkbox,
	Alert
} from 'antd';
import * as moment from 'moment';

import CategoryDropdown from './CategoryDropdown';
import SubCategoryDropdown from './SubCategoryDropdown';
import UploadForm from '../UploadForm';

import { getProductById, updateProductById } from '../../actions/productActions';

const ViewProduct = ({ productStatus, id, open, handleOpenView, categoryReducer, updateProductById }) => {
	const [isDisableBtn, setIsDisableBtn] = useState(false);
	const [product, setProduct] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState([]);
	const [newImage, setNewImage] = useState(null);
	const [categorySelected, setCategorySelected] = useState(null);
	const [form] = Form.useForm();

    useEffect(() => {
        const _getProductById = async id => {
			setIsLoading(true);
			try {
				const _product = await getProductById(productStatus, id);
				if (_product.data && _product.data !== null && _product.data.getProductById !== null) {
					setProduct(_product.data.getProductById);
					setCategorySelected(_product.data.getProductById.category);
				} else {
					setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
				}
			} catch (error) {
				console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
			}
			setIsLoading(false);
		}
		
		const _getCategory = async () => {
			setCategories(categoryReducer.categories);
			setCategory(categoryReducer.categories.map(item => item.name));
		}
		_getProductById(id);
		_getCategory();
	}, []);

    const onClose = () => {
        handleOpenView(false);
	}

	const onFinish = async values => {
		const isUpdateProduct = await updateProductById(values, id);
		if (isUpdateProduct) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage('เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง');
		}
	};

	const handleSetNewImage = (imageUrl) => {
		setNewImage(imageUrl)
	}

	const updateCategory = v => {
		form.setFieldsValue({ categorySub: "" });
		setCategorySelected(v);
	}

	const renderProduct = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}

		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
				onFinish={onFinish} 
				initialValues={{
					category: product.category,
					categorySub: product.categorySub,
					code: product.code,
					name: product.name,
					price: product.price,
					specialPrice: product.specialPrice,
					balance: product.balance,
					weight: product.weight,
					detail: product.detail,
					isPromotion: product.isPromotion,
					isRecommend: product.isRecommend,
					active: product.active,
				}}
			>
				<Row gutter={16}>
					<Col span={12}>
						<CategoryDropdown updateCategory={updateCategory} category={category} />
					</Col>
					<Col span={12}>
						<SubCategoryDropdown categories={categories} categorySelected={categorySelected} />
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="code"
							label="รหัสสินค้า"
							rules={[
								{ required: true, message: 'กรุณากรอกรหัสสินค้า' },
								{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
							]}
						>
						<Input placeholder="กรอกรหัสสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="name"
							label="ชื่อสินค้า"
							rules={[{ required: true, message: 'กรุณากรอกชื่อสินค้า' }]}
						>
						<Input placeholder="กรอกชื่อสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="price"
							label="ราคา"
							rules={[{ 
								required: true,
								message: 'กรุณากรอกราคาสินค้าเป็นตัวเลขเท่านั้น' 
							},
							{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
						]}
						>
						<Input placeholder="กรอกราคาสินค้า" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="specialPrice"
							label="ราคาพิเศษ"
							rules={[{ 
								required: true,
								message: 'กรุณากรอกราคาพิเศษเป็นตัวเลขเท่านั้น'
							},
							{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
						]}
						>
						<Input placeholder="กรอกราคาพิเศษ" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name="balance"
							label="จำนวนสินค้าคงเหลือ"
							rules={[{ 
								required: true,
								message: 'กรุณากรอกจำนวนสินค้าคงเหลือเป็นตัวเลขเท่านั้น'
							},
							{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
						]}
						>
						<Input placeholder="กรอกจำนวนสินค้าคงเหลือ" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="weight"
							label="น้ำหนัก"
							rules={[{
								required: true,
								message: 'กรุณากรอกน้ำหนักสินค้าเป็นตัวเลขเท่านั้น'
							},
							{ pattern: new RegExp(/^[0-9.]+$/i), message: 'กรุณากรอกเฉพาะตัวเลขเท่านั้น' }
						]}
						>
						<Input placeholder="กรอกน้ำหนักสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="detail"
							label="รายละเอียด"
							rules={[
								{
									required: true,
									message: 'กรุณากรอกรายละเอียดสินค้า',
								}
							]}
						>
							<Input.TextArea rows={4} placeholder="กรอกรายละเอียดสินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item  name="isPromotion" valuePropName="checked">
							<Checkbox>สินค้าโปรโมชั่น</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item  name="isRecommend" valuePropName="checked">
							<Checkbox>สินค้าแนะนำ</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item  name="active" valuePropName="checked">
							<Checkbox>Active</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12} >
						{product.urlImage ? <img width="340" src={`${product.urlImage}?${+moment()}`} alt="product image" /> : 'No image'}
					</Col>
					<Col span={12}>
						<UploadForm moduleUpload="product" id={product._id} text="อัพโหลดภาพใหม่" afterUpload={handleSetNewImage} />
						<h5><font color="#f55c47">รูปภาพขนาด กว้าง 450 x สูง 450 (เฉพาะนามสกุล .jpg .jpeg .png เท่านั้น)</font></h5>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit">
					บันทึกข้อมูล
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}

    return (
        <Drawer
			title="แก้ไขข้อมูลสินค้า"
			width={720}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderProduct()}
			</div>
        </Drawer>
    )
}

const mapStateToProps = ({ category }) => {
    return { categoryReducer: category };
}

ViewProduct.propTypes = {
	categoryReducer: PropTypes.object.isRequired,
	updateProductById: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { updateProductById })(ViewProduct);