import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Card,
	Row,
	Col,
	Button,
	Select,
	Alert,
	Popconfirm,
} from 'antd';
import { 
    PrinterOutlined
} from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import OrderToPrint from './OrderToPrint';
import { getOrderById, changeStateOrder } from '../../actions/orderActions';

const ViewProduct = ({ id, open, handleOpenView, changeStateOrder }) => {
	const { Option } = Select;

	const [selectLoading, setSelecteLoading] = useState(false);
	const [order, setOrder] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleChangeState = async value => {
		setSelecteLoading(true);
		const obj = {
			newState: value,
			id,
		}
		const isUpdated = await changeStateOrder(obj);
		if (isUpdated.status) {
			setIsError(false);
			setIsComplete(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isUpdated.message);
		}
		setSelecteLoading(false);
	}

    useEffect(() => {
        const _getOrderById = async id => {
			setIsLoading(true);
			try {
				const _order = await getOrderById(id);
				if (_order.data && _order.data !== null && _order.data.getOrderById !== null) {
					setOrder(_order.data.getOrderById);
				} else {
					setErrMessage('เกิดข้อผิดพลาด!!');
					setIsError(true);
				}
			} catch (error) {
				console.log(error);
				setErrMessage('เกิดข้อผิดพลาด!!');
                setIsError(true);
			}
			setIsLoading(false);
		}
		_getOrderById(id);
	}, []);

    const onClose = () => {
        handleOpenView(false);
	}

	const renderOrder = () => {
		if (isLoading) {
			return <div>Loading...</div>
		}

		return (
			<React.Fragment>
				<Button style={{ marginBottom: '20px' }} type="primary" shape="round" onClick={handlePrint} icon={<PrinterOutlined />} size="large">
					พิมพ์
				</Button>
				<OrderToPrint order={order} ref={componentRef} />
				<Card title="Dangerous Zone" headStyle={{ color: '#cc1c1c', border: 0 }} style={{ marginBottom: '20px', borderColor: '#cc1c1c' }}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<Select loading={selectLoading} defaultValue={order.state} size="large" style={{ width: '350px' }} onChange={handleChangeState}>
								<Option value="PAYMENT">รอชำระเงิน</Option>
								<Option value="PREPARE_PRODUCT">เตรียมจัดส่ง</Option>
								<Option value="DELIVERY">จัดส่งแล้ว</Option>
								<Option value="CANCEL_BY_ADMIN" disabled={true}>ยกเลิกโดย Admin</Option>
								<Option value="CANCEL_BY_SYSTEM" disabled={true}>ไม่ชำระเงินเกินเวลาที่กำหนด</Option>
							</Select>
						</Col>
						<Col span={12}>

							<Popconfirm
								title="ยืนยันที่จะยกเลิกรายการสั่งซื้อนี้?"
								onConfirm={e => handleChangeState('CANCEL_BY_ADMIN')}
								okText="ใช่"
								cancelText="ไม่"
							>
								<Button size="large" block type="primary">ยกเลิกรายการสั่งซื้อนี้</Button>
							</Popconfirm>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
							{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
						</Col>
					</Row>
				</Card>
			</React.Fragment>
		)
	}

    return (
        <Drawer
			title="รายการสั่งซื้อสินค้า"
			width={1200}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderOrder()}
			</div>
        </Drawer>
    )
}

ViewProduct.propTypes = {
	changeStateOrder: PropTypes.func.isRequired,
}

export default connect(null, { changeStateOrder })(ViewProduct);