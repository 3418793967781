import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

// import Customer from '../components/Customer';
import Banner from '../components/Banner';

const BannerPage = () => {
    const { Content } = Layout;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <Banner />
            </Content>
        </MainLayout>
    )
}

export default BannerPage;