import React from 'react';
import { Layout } from 'antd';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SideBar from '../components/SideBar';
import './MainLayout.scss';

const MainLayout = ({ children }) => {
    return (
        <Layout className="App">
            <SideBar />
            <Layout>
                <Header />
                {children}
                <Footer />
            </Layout>
        </Layout>
    );
}

export default MainLayout;