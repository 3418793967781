import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Form,
    Button,
    Modal,
    Select,
    Row,
    Col,
    Alert,
} from 'antd';

import { checkExistingSubCategory, deleteSubCategory } from '../../actions/categoryAction';

const { Option } = Select;

const DeleteSubCategory = ({ categorySubCode, categorySubName, category, handleCancel, modalVisible, deleteSubCategory }) => {
    const [, forceUpdate] = useState();
    const [categorySelected, setCategorySelected] = useState('');
    const [subCategorySelected, setSubCategorySelected] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subCategory, setSubCategory] = useState([]);
    const [productExisting, setProductExisting] = useState(true);

    useEffect(() => {
        const _checkExisting = async () => {
            setIsLoading(true);
            const check = await checkExistingSubCategory(categorySubName);
            setProductExisting(check);
            setIsLoading(false);
        }

        _checkExisting();
        forceUpdate({});
    }, []);

	const handleOk = async () => {
        let deleteObj = {};
        deleteObj.type = categorySelected !== '' && subCategorySelected !== '' ? 'm' : 'n';
        deleteObj.category = categorySelected;
        deleteObj.categorySub = subCategorySelected;
        deleteObj.categorySubCode = categorySubCode;
        deleteObj.categorySubName = categorySubName;

        await deleteSubCategory(deleteObj);

        setDeleteLoading(false);
        closeModal();
    };

    const closeModal = () => {
        handleCancel();
        setCategorySelected('');
        setSubCategorySelected('');
    }

    const renderCategory = () => {
        const { isLoading, categories } = category;
        if (isLoading) {
            return <Option value="" key="99">Loading...</Option>;
        }

        return categories.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>)
    }

    const updateCategory = v => {
        const { categories } = category;
        setCategorySelected(v);
        setSubCategorySelected('');
        const [{sub}] = categories.filter(item => item.name === v)
        setSubCategory(sub);
    }
    
    const updateSubCategory = v => {
        setSubCategorySelected(v);
    }

    return (
        <Modal
            visible={modalVisible}
            title="ยืนยันการลบหมวดหมู่"
            onOk={handleOk}
            onCancel={closeModal}
            footer={[
                <Button key="back" onClick={closeModal}>
                    ยกเลิก
                </Button>,
                <Button key="submit" disabled={productExisting ? categorySelected === null || subCategorySelected === "" ? true : false : false} type="primary" loading={deleteLoading} onClick={handleOk}>
                    {productExisting ? 'ยืนยันการย้ายหมวดหมู่และลบหมวดหมู่ย่อยนี้' : 'ยืนยันการลบ'}
                </Button>,
            ]}
            >
                {isLoading ? <div>Loading...</div> : productExisting ? 
                    <React.Fragment>
                        <Alert message="มีสินค้าค้างอยู่ในหมวดหมู่นี้ กรุณาเลือกหมวดหมู่เพื่อย้ายสินค้าทั้งหมด ก่อนทำการลบหมวดหมู่นี้" type="error" showIcon />
                        <Form style={{ marginTop: '20px' }}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item>
                                    <Select 
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={categorySelected} 
                                        onChange={updateCategory} 
                                        placeholder="กรุณาเลือกหมวดหมู่สินค้า"
                                    >
                                        <Option value="" key="0">เลือกหมวดหมู่สินค้าย่อย</Option>
                                        {renderCategory()}
                                    </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                    <Select 
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={subCategorySelected} 
                                        onChange={updateSubCategory}
                                        placeholder="กรุณาเลือกหมวดหมู่สินค้าย่อย"
                                    >
                                        <Option value="" key="0">เลือกหมวดหมู่สินค้าย่อย</Option>
                                        {subCategory.map((item, i) => <Option key={i+1} value={item.name}>{item.name}</Option>)}
                                    </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </React.Fragment>
            : null }
        </Modal>
    );
};

const mapStateToProps = ({ category }) => {
    return { category };
}

DeleteSubCategory.propTypes = {
    category: PropTypes.object.isRequired,
    deleteSubCategory: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { deleteSubCategory })(DeleteSubCategory);