import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	CodeSandboxOutlined,
	HomeOutlined,
	DropboxOutlined,
	TeamOutlined,
	ContainerOutlined,
	TagOutlined,
	TagsOutlined,
	SettingOutlined,
	FundOutlined,
	UserOutlined,
	FormOutlined,
	SoundOutlined,
	FireOutlined,	
} from '@ant-design/icons';

import { HOME, PRODUCT, CATEGORY, SUBCATEGORY, ORDER, CUSTOMER, ADJUST_STOCK, USER, BANNER, IMPORT_PRODUCT, IMPORT_COUPON, COUPON, HTML, MAINTENANCE, SHOCK_SALE, PROMOTION_MEMBER } from '../../constants/routes';
import './SideBar.scss';
import { STATIC_IMG_PATH } from '../../constants/config';
import { setMenuActive, setOpen } from '../../actions/menuActions';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ menu, setMenuActive, setOpen }) => {
	const [rootSubmenuKeys ] = useState(['sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'sub7', 'sub8']);
	const handleClickMenu = e => {
		setMenuActive(e.key);
	}

	const handleOpenChange = openKeys => {
		const latestOpenKey = openKeys.find(key => menu.open.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpen(openKeys);
		} else {
			const openKeys = latestOpenKey ? [latestOpenKey] : [];
			setOpen(openKeys);
		}
	}

    return (
        <Sider
			breakpoint="lg"
			collapsedWidth="0"
			onBreakpoint={broken => {
				// console.log(broken);
			}}
			width="250"
			onCollapse={(collapsed, type) => {
				console.log(collapsed, type);
			}}
		>
        	<div className="logo">
				<img src={STATIC_IMG_PATH+'logo-big-one-2.png'} alt="SPC" />
			</div>
			<Menu 
				theme="dark"
				mode="inline"
				onClick={handleClickMenu}
				onOpenChange={handleOpenChange}
				selectedKeys={menu.selected}
				openKeys={menu.open}
			>
				<Menu.Item key="1">
					<Link to={HOME}>
						<HomeOutlined /> หน้าหลัก
					</Link>
				</Menu.Item>
				<SubMenu key="sub2" title={<span><TagsOutlined /> จัดการหมวดหมู่สินค้า</span>}>
					<Menu.Item key="2">
						<Link to={CATEGORY}>
							<TagOutlined /> หมวดหมู่สินค้า
						</Link>
					</Menu.Item>
					<Menu.Item key="3">
						<Link to={SUBCATEGORY}>
							<TagsOutlined /> หมวดหมู่ย่อยสินค้า
						</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub3" title={<span><CodeSandboxOutlined /> จัดการสินค้า</span>}>
					<Menu.Item key="4">
						<Link to={IMPORT_PRODUCT}>
							<CodeSandboxOutlined /> Import Product
						</Link>
					</Menu.Item>
					<Menu.Item key="5">
						<Link to={PRODUCT}>
							<CodeSandboxOutlined /> Product
						</Link>
					</Menu.Item>
					<Menu.Item key="6">
						<Link to={ADJUST_STOCK}>
							<DropboxOutlined /> Adjust Stock
						</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub4" title={<span><CodeSandboxOutlined /> จัดการรายการสั่งซื้อ</span>}>
					{/* <Menu.Item key="7">
						<Link to={ORDER+'/new'}>
							<ContainerOutlined /> รายการสั่งซื้อล่าสุด
						</Link>
					</Menu.Item> */}
					<Menu.Item key="7">
						<Link to={ORDER+'/all'}>
							<ContainerOutlined /> รายการสั่งซื้อทั้งหมด
						</Link>
					</Menu.Item>
				</SubMenu>
				<Menu.Item key="8">
					<Link to={BANNER}>
						<FundOutlined /> แบนเนอร์
					</Link>
				</Menu.Item>
				<SubMenu key="sub5" title={<span><CodeSandboxOutlined /> คูปอง</span>}>
					<Menu.Item key="9">
						<Link to={IMPORT_COUPON}>
							<CodeSandboxOutlined /> Import Coupon
						</Link>
					</Menu.Item>
					<Menu.Item key="10">
						<Link to={COUPON}>
							<CodeSandboxOutlined /> Coupon
						</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub6" title={<span><SoundOutlined /> Promotion</span>}>
					<Menu.Item key="11">
						<Link to={SHOCK_SALE}>
							<FireOutlined /> Shock sale
						</Link>
					</Menu.Item>
					<Menu.Item key="12">
						<Link to={PROMOTION_MEMBER}>
							<FireOutlined /> Promotion member
						</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub7" title={<span><FormOutlined /> HTML</span>}>
					<Menu.Item key="13">
						<Link to={HTML + "/howtobuy"}>
							<FormOutlined /> ขั้นตอนการสั่งซื้อ
						</Link>
					</Menu.Item>
					<Menu.Item key="14">
						<Link to={HTML + "/payment"}>
							<FormOutlined /> วิธีการชำระเงิน
						</Link>
					</Menu.Item>
					<Menu.Item key="15">
						<Link to={HTML + "/privacy"}>
							<FormOutlined /> นโยบายความเป็นส่วนตัว
						</Link>
					</Menu.Item>
					<Menu.Item key="16">
						<Link to={HTML + "/condition"}>
							<FormOutlined /> ข้อกำหนดเงื่อนไข
						</Link>
					</Menu.Item>
					<Menu.Item key="17">
						<Link to={HTML + "/contactus"}>
							<FormOutlined /> ข้อมูลติดต่อเรา
						</Link>
					</Menu.Item>
					<Menu.Item key="18">
						<Link to={HTML + "/return-product"}>
							<FormOutlined /> การคืนสินค้า
						</Link>
					</Menu.Item>
					<Menu.Item key="19">
						<Link to={HTML + "/cookie"}>
							<FormOutlined /> นโยบายการใช้งานคุ้กกี้
						</Link>
					</Menu.Item>
				</SubMenu>
				<SubMenu key="sub8" title={<span><SettingOutlined /> ตั้งค่าระบบ</span>}>
					<Menu.Item key="20">
						<Link to={USER}>
							<UserOutlined /> จัดการผู้ใช้งาน
						</Link>
					</Menu.Item>
					<Menu.Item key="21">
						<Link to={CUSTOMER}>
							<TeamOutlined /> จัดการลูกค้า
						</Link>
					</Menu.Item>
					<Menu.Item key="22">
						<Link to={MAINTENANCE}>
							<SettingOutlined /> จัดการการปรับปรุงเว็บไซต์
						</Link>
					</Menu.Item>
				</SubMenu>
			</Menu>
      	</Sider>
    );
}

const mapStateToProp = ({ menu }) => {
	return { menu }
}

SideBar.propTypes = {
	menu: PropTypes.object.isRequired,
	setMenuActive: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
}

export default connect(mapStateToProp, { setMenuActive, setOpen })(SideBar);