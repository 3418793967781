import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
	PlusSquareOutlined
} from '@ant-design/icons';

import PageHeader from '../Shared/PageHeader';
import TableProduct from './TableProduct';
import ViewProduct from './ViewProduct';
import AddProduct from './AddProduct';
import FilterForm from './FilterForm';
import './Product.scss';

import { getCategories, getProductByCategory } from '../../actions/productActions';

const Product = ({ getCategories, getProductByCategory, product }) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [productId, setProductId] = useState('');
    const [productStatus, setProductStatus] = useState(false);
    const [carryFilter, setCarryFilter] = useState({});

    const handleOpenView = status => {
        setOpenView(status);
    }

    const getProduct = async values => {
        setCarryFilter(values);
        await getProductByCategory(
            values.code,
            values.category,
            values.categorySub,
            values.active,
            10,
            values.productName,
            1
        );
    }

    const handleTableChange = async (paginate, filters) => {
        await getProductByCategory(
            carryFilter.code,
            carryFilter.category,
            carryFilter.categorySub,
            carryFilter.active,
            paginate.pageSize,
            carryFilter.productName,
            paginate.current
        );
    }

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    const updateProductId = id => {
        setProductId(id);
    }

    const updateProductStatus = status => {
        setProductStatus(status);
    }

    useEffect(() => {
        const _getCategory = async () => {
            await getCategories();
        }
        _getCategory();
    }, []);

    return (
        <div className="product">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการสินค้า (Product)" subTitle="เพิ่ม / แก้ไข / ลบ สินค้า" btn={{ 
                        text: 'เพิ่มสินค้าใหม่',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FilterForm getProduct={getProduct} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableProduct
                        handleTableChange={handleTableChange}
                        pagination={product.pagination}
                        updateProductId={updateProductId}
                        updateProductStatus={updateProductStatus}
                        handleOpenView={handleOpenView}
                        loading={product.isLoading}
                        data={product.products}
                    />
                </Col>
            </Row>
            {openAdd && <AddProduct handleOpenAdd={handleOpenAdd} open={openAdd} />}
            {openView && <ViewProduct productStatus={productStatus} id={productId} handleOpenView={handleOpenView} open={openView} />}
        </div>
    );
}

const mapStateToProps = ({ product }) => {
    return { product };
}

Product.propTypes = {
    getCategories: PropTypes.func.isRequired,
    getProductByCategory: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getCategories, getProductByCategory })(Product);