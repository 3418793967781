import {
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_ERROR,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_ERROR,
    DELETE_MULTI_PRODUCT_REQUEST,
    DELETE_MULTI_PRODUCT_SUCCESS,
} from '../actionTypes';

const initialState = {
    isLoading: false,
    isError: false,
    products: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    product: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case DELETE_MULTI_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
        case CREATE_PRODUCT_REQUEST:
        case GET_PRODUCT_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_PRODUCT_BY_CATEGORY:
            return {
                ...state,
                products: action.payload,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                    total: action.payload.totalDocs,
                },
                isLoading: false,
            }
        case CREATE_PRODUCT_ERROR:
        case GET_PRODUCT_BY_CATEGORY_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    docs: state.products.docs.map(product => {
                        if (product._id === action.payload.updateProductById._id) {
                            return action.payload.updateProductById;
                        } else {
                            return {...product}
                        }
                    })
                },
                isLoading: false,
            }
        case CREATE_PRODUCT_SUCCESS:
            if (state.products.docs) {
                return {
                    ...state,
                    products: {
                        ...state.products,
                        docs: [action.payload.createProduct, ...state.products.docs],
                        totalDocs: state.products.totalDocs + 1,
                    },
                    pagination: {
                        ...state.pagination,
                        total: state.products.totalDocs + 1,
                    },
                    isLoading: false,
                }
            }

            return {
                ...state,
                isLoading: false,
            }
        case DELETE_MULTI_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    docs: state.products.docs.filter(item => !action.payload.includes(item._id)),
                    totalDocs: state.products.totalDocs - action.payload.length,
                },
                pagination: {
                    ...state.pagination,
                    total: state.products.totalDocs - action.payload.length,
                },
                isLoading: false,
            }
    
        default:
            return state;
    }
}