import fetch from 'isomorphic-fetch';
import { URL_GRAPHQL, STORAGE_TOKEN } from '../constants/config';

import {
    GET_CUSTOMER_REQUEST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_REQUEST,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_ERROR,
} from '../actionTypes';

import { DECODE_DATA, ENCODE_DATA } from '../services/storage';
import { getCookie } from './authActions';

export const getCustomer = ({ current, pageSize }, search) => async dispatch => {
    dispatch({ type: GET_CUSTOMER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            users(page: ${current}, limit: ${pageSize}, search: ${search}) {
                docs {
                    _id
                    firstname
                    lastname
                    email
                    phone
                    active
                    memberState
                }
                totalDocs
                page
            }
        }`
    }
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify(query)
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.users) {
            dispatch({
                type: GET_CUSTOMER_SUCCESS,
                payload: res.data.users,
            })
        } else {
            dispatch({
                type: GET_CUSTOMER_ERROR,
                payload: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            })
        }
    })
    .catch(err => console.log('err', err));
}

export const getCustomerById = async id => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer  ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query = {
        query: `{
            user(id: "${id}") {
                _id
                firstname
                lastname
                email
                addresses {
                    firstname
                    lastname
                    phone
                    addr1
                    village
                    subDistrict
                    district
                    province
                    country
                    zip
                    isShipping
                    isInvoice
                }
                birth
                phone
                sex
                isAdmin
                created
                active
                oceanMemberCard
                citizenId
                memberState
            }
        }`
    }
    return fetch(URL_GRAPHQL, { method: 'POST', headers, body: JSON.stringify(query) })
    .then(res => res.json())
    .then(res => res)
    .catch(err => console.log('err', err));
}

export const updateProfile = (id, obj) => async dispatch => {
    dispatch({ type: UPDATE_CUSTOMER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateProfileById($obj: UserInput!, $id: String!) {
            updateProfileById(userInput: $obj, id: $id) {
                _id
                firstname
                lastname
                name
                email
                phone
                active
                created
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                obj,
                id,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateProfileById) {
            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: res.data.updateProfileById,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_CUSTOMER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}

export const updateProfileByAdmin = (id, obj) => async dispatch => {
    dispatch({ type: UPDATE_CUSTOMER_REQUEST });
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`,
    }
    let query =
        `mutation updateProfileByAdmin($obj: UserInput!, $id: String!) {
            updateProfileByAdmin(userInput: $obj, id: $id) {
                _id
                firstname
                lastname
                name
                email
                phone
                active
                created
                oceanMemberCard
                citizenId
                memberState
            }
        }`
    return fetch(URL_GRAPHQL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query,
            variables: {
                obj,
                id,
            }
        })
    })
    .then(res => res.json())
    .then(res => {
        if (res.data && res.data !== null && res.data.updateProfileByAdmin) {
            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                payload: res.data.updateProfileByAdmin,
            });
            return {
                status: true,
                message: null,
            }
        } else {
            dispatch({
                type: UPDATE_CUSTOMER_ERROR,
            });
            return {
                status: false,
                message: res.errors.length > 0 ? res.errors[0].message : 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
            }
        }
    })
    .catch(err => console.log('err', err));
}