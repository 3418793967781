import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    Form,
    Button,
    Modal,
    Select,
    Row,
    Col
} from 'antd';

import { changeStateOrders } from '../../actions/orderActions';

const { Option } = Select;

const ChangeStateOrder = ({ clearSelected, handleCancel, modalVisible, selectedRowKeys, changeStateOrders }) => {
    const [, forceUpdate] = useState();
    const [stateSelected, setStateSelected] = useState('');
    const [changeStateLoading, setChangeStateLoading] = useState(false);

    useEffect(() => {
        forceUpdate({});
    }, []);

	const handleOk = async () => {
        setChangeStateLoading(true);
        if (selectedRowKeys.length > 0 && stateSelected !== '') {
            const obj = {
                ids: selectedRowKeys,
                newState: stateSelected,
            }
            const isUpdated = await changeStateOrders(obj);
            if (isUpdated.status) {
                setTimeout(() => {
                    clearSelected();
                    setChangeStateLoading(false);
                    closeModal();
                }, 1500);
            } else {
                alert('เกิดข้อผิดพลาดกรุณาตรวจสอบใหม่อีกครั้ง');
            }

        }
    };

    const closeModal = () => {
        handleCancel();
    }

    const handleChangeState = v => {
        setStateSelected(v);
    }

    return (
        <Modal
            visible={modalVisible}
            title="เปลี่ยนสถานะรายการสินค้า"
            onOk={handleOk}
            onCancel={closeModal}
            footer={[
                <Button key="back" onClick={closeModal}>
                    ยกเลิก
                </Button>,
                <Button key="submit" disabled={stateSelected === null || stateSelected === "" ? true : false} type="primary" loading={changeStateLoading} onClick={handleOk}>
                    ยืนยันการเปลี่ยนสถานะ
                </Button>,
            ]}
            >
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item>
                        <Select size="large" value={stateSelected} onChange={handleChangeState} placeholder="กรุณาเลือกหมวดหมู่สินค้า">
                            <Option value="">เลือกสถานะ</Option>
                            <Option value="PAYMENT">ชำระเงิน</Option>
                            <Option value="PREPARE_PRODUCT">เตรียมจัดส่ง</Option>
                            <Option value="DELIVERY">จัดส่งแล้ว</Option>
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

ChangeStateOrder.propTypes = {
    changeStateOrders: PropTypes.func.isRequired,
}

export default connect(null, { changeStateOrders })(ChangeStateOrder);