import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
	PlusSquareOutlined
} from '@ant-design/icons';

import PageHeader from '../Shared/PageHeader';
import TableCategory from './TableCategory';
import AddCategory from './AddCategory';
import ViewCategory from './ViewCategory';
import './Category.scss';

import { getCategories } from '../../actions/productActions';

const Category = ({ getCategories }) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [categoryName, setCategoryName] = useState(null);
    const [categoryCode, setCategoryCode] = useState(null);

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    const handleOpenView = status => {
        setOpenView(status);
    }

    const updateCategoryName = name => {
        setCategoryName(name);
    }

    const updateCategoryCode = code => {
        setCategoryCode(code);
    }

    useEffect(() => {
        const _getCategory = async () => {
            await getCategories();
        }
        _getCategory();
    }, []);

    return (
        <div className="category">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการหมวดหมู่ (Category)" subTitle="เพิ่ม / แก้ไข / ลบ หมวดหมู่สินค้า" btn={{ 
                        text: 'เพิ่มหมวดหมู่ใหม่',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableCategory
                        handleOpenView={handleOpenView}
                        updateCategoryName={updateCategoryName}
                        updateCategoryCode={updateCategoryCode}
                    />
                </Col>
            </Row>
            {openAdd && <AddCategory handleOpenAdd={handleOpenAdd} open={openAdd} />}
            {openView && <ViewCategory handleOpenView={handleOpenView} categoryName={categoryName} categoryCode={categoryCode} open={openView} />}
        </div>
    )
}

Category.propTypes = {
    getCategories: PropTypes.func.isRequired,
}

export default connect(null, { getCategories })(Category);