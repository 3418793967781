import React from 'react';
import { PageHeader } from 'antd'

const PageHeaders = () => {
    return (
        <PageHeader
            title="ระบบจัดการเปิดปิดการปรับปรุงเว็บไซต์"
            subTitle="เปิด / ปิด Maintenance Page"
        />
    );
}

export default PageHeaders;