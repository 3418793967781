import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import {
	PlusSquareOutlined
} from '@ant-design/icons';
import * as _ from 'lodash';

import PageHeader from '../Shared/PageHeader';
import TableBanner from './TableBanner';
import AddBanner from './AddBanner';
import './Banner.scss';

import { getBanner } from '../../actions/bannerAction';
import ViewBanner from './ViewBanner';

const Banner = ({ getBanner, banner }) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [bannerId, setBannerId] = useState('');
    const [bannerStatus, setBannerStatus] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filter, setFilter] = useState('{}');

    const handleOpenAdd = () => {
        const drawerStatus = !openAdd;
        setOpenAdd(drawerStatus);
    }

    const handleTableChange = (paginate, filters, extra) => {
        if (!_.isEmpty(filters, true)) {
            let searchStr = '{';
            for (const property in filters) {
                searchStr += `${property}: "${filters[property]}",`;
            }
            searchStr = searchStr.slice(0, -1);
            searchStr += '}';
            setFilter(searchStr);
            if (extra.action === 'filters') {
                setPagination({ current: 1, pageSize: 10 });
            } else {
                setPagination(paginate);
            }
        } else {
            setPagination(paginate);
            setFilter('{}');
        }
    }
    
    const handleOpenView = status => {
        setOpenView(status);
    }

    const updateBannerId = id => {
        setBannerId(id);
    }

    const updateBannerStatus = status => {
        setBannerStatus(status);
    }

    useEffect(() => {
        const _getBanner = async () => {
            await getBanner(pagination, filter);
        }
        _getBanner();
    }, [pagination]);

    return (
        <div className="banner">
            <Row>
                <Col span={24}>
                    <PageHeader title="ระบบจัดการแบนเนอร์ (Banner)" subTitle="เพิ่ม / แก้ไข / ลบ แบนเนอร์" btn={{ 
                        text: 'เพิ่มรูปแบนเนอร์',
                        fnc: handleOpenAdd,
                        visible: true,
                        icon: <PlusSquareOutlined />,
                        size: 'middle',
                        type: 'default'
                    }} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TableBanner
                        loading={banner.isLoading}
                        data={banner.banners}
                        updateBannerStatus={updateBannerStatus}
                        handleOpenView={handleOpenView}
                        updateBannerId={updateBannerId}
                        pagination={banner.pagination}
                        handleTableChange={handleTableChange}
                    />
                </Col>
            </Row>
            {openAdd && <AddBanner handleOpenAdd={handleOpenAdd} open={openAdd} />}
            {openView && <ViewBanner bannerStatus={bannerStatus} id={bannerId} handleOpenView={handleOpenView} open={openView} />}
        </div>
    )
}

const mapStateToProps = ({ banner }) => {
    return { banner }
}

Banner.propTypes = {
    getBanner: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getBanner })(Banner);