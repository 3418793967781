import React, { useState } from 'react';
import { Upload, message, Divider, List, Typography } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { STORAGE_TOKEN, API_URL } from '../../constants/config';
import { DECODE_DATA, ENCODE_DATA } from '../../services/storage';
import { getCookie } from '../../actions/authActions';

const { Dragger } = Upload;

const Import = () => {
    const [isError, setIsError] = useState(false);
    const [errors, setErrors] = useState([]);

    const props = {
        name: 'file',
        multiple: false,
        headers: {
            'Authorization': `Bearer ${DECODE_DATA(getCookie(ENCODE_DATA(STORAGE_TOKEN, false)))}`
        },
        action: `${API_URL}/coupon/import`,
        onChange(info) {
            setIsError(false);
            const { status } = info.file;

            if (status !== 'uploading') {
                if (status === 'error') {
                    setIsError(true);
                    if (info.file.response && info.file.response.errors && info.file.response.errors.length > 0) {
                        setErrors(info.file.response.errors);
                    } else {
                        setErrors(['Please contact admin!!']);
                    }
                }
            }

            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
      };
    
    return (
        <React.Fragment>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click หรือ drop ไฟล์เพื่ออัพโหลดข้อมูล</p>
            </Dragger>
            {
                isError ? (
                    <React.Fragment>
                        <Divider style={{ marginTop: '20px' }} orientation="left"><span style={{ color: 'red' }}>Error !!</span></Divider>
                        <List
                            style={{ marginTop: '20px' }}
                            bordered
                            dataSource={errors}
                            renderItem={item => (
                                <List.Item>
                                <Typography.Text mark>[Error]</Typography.Text> {item}
                                </List.Item>
                            )}
                        />
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    )
}

export default Import;