export const HOME = '/';
export const PRODUCT = '/product';
export const SIGNIN = '/signin';
export const CATEGORY = '/category';
export const SUBCATEGORY = '/subcategory';
export const ORDER = '/order';
export const CUSTOMER = '/customer';
export const ADJUST_STOCK = '/adjust_stock';
export const USER = '/user';
export const BANNER = '/banner';
export const IMPORT_PRODUCT = '/import_product';
export const IMPORT_COUPON = '/import_coupon';
export const COUPON = '/coupon';
export const HTML = '/html';
export const MAINTENANCE = '/maintenance';
export const SHOCK_SALE = '/shock_sale';
export const PROMOTION_MEMBER = '/promotion_member';