import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
	Drawer,
	Form,
	Button,
	Col,
	Row,
	Input,
	Alert
} from 'antd';

import { updateCategory } from '../../actions/categoryAction';

const ViewCategory = ({ open, handleOpenView, categoryCode, categoryName, updateCategory }) => {
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    // const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [errMessage, setErrMessage] = useState(null);
    const [form] = Form.useForm();

    const onClose = () => {
        handleOpenView(false);
    }

    const onFinish = async values => {
		values.oldName = categoryName;
		values.code = categoryCode;
		
		const isUpdatedCategory = await updateCategory(values);
		if (isUpdatedCategory.status) {
			setIsError(false);
			setIsComplete(true);
			setIsDisableBtn(true);
		} else {
			setIsError(true);
			setIsComplete(false);
			setErrMessage(isUpdatedCategory.message);
		}
    };

    const renderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical" 
				hideRequiredMark 
                onFinish={onFinish}
                initialValues={{
					code: categoryCode,
                    category: categoryName
                }}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="code"
							label="รหัส"
						>
						<Input disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="category"
							label="ชื่อหมวดหมู่สินค้า"
							rules={[{ required: true, message: 'กรุณากรอกชื่อหมวดหมู่สินค้า' }]}
						>
						<Input placeholder="กรอกชื่อหมวดหมู่สินค้า" />
						</Form.Item>
					</Col>
				</Row>
				<Button style={{marginBottom: '25px'}} disabled={isDisableBtn} type="primary" htmlType="submit" block={true}>
					บันทึก
				</Button>
				{isComplete ? <Alert message="บันทึกข้อมูลสำเร็จ" type="success" showIcon /> : null}
				{isError ? <Alert message={errMessage} type="error" showIcon /> : null}
          </Form>
		)
	}
    
    return (
        <Drawer
			title="แก้ไขหมวดหมู่"
			width={420}
			placement="right"
			closable={true}
			onClose={onClose}
			visible={open}
			bodyStyle={{ paddingBottom: 80 }}
        >
			<div className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
				{renderForm()}
			</div>
        </Drawer>
    )
}

ViewCategory.propTypes = {
	updateCategory: PropTypes.func.isRequired,
}

export default connect(null, { updateCategory })(ViewCategory);