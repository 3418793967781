import { ENCRYPTION_ROUND } from '../constants/config';

export function ENCODE_DATA(_data, _jsonStringify = true, _round = ENCRYPTION_ROUND) {
    try {
        if (_data === null || _data === undefined) { return null; }
        let newData = _jsonStringify ? JSON.stringify(_data) : _data;
        for (let i = 0; i < _round; i++) {
            newData = Buffer.from(newData).toString('base64');
        }
        return newData;
    } catch (error) {
        console.log('ENCODE_STORAGE', error);
        return null;
    }
}

export function DECODE_DATA(_data, _jsonParse = true, _round = ENCRYPTION_ROUND) {
    try {    
        if (_data === null || _data === undefined) { return null; }         
        let newData = _data;
        for (let i = 0; i < _round; i++) {
            newData = Buffer.from(newData, 'base64').toString();
        } 
        return _jsonParse ? JSON.parse(newData) : newData;
    } catch (error) {
        console.log('DECODE_STORAGE', error);
        return null;
    }
}