import { 
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_ERROR,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,
    CREATE_CATEGORY_SUB_REQUEST,
    CREATE_CATEGORY_SUB_ERROR,
    UPDATE_CATEGORY_SUB_REQUEST,
    UPDATE_CATEGORY_SUB_ERROR,
    DELETE_CATEGORY_SUB_REQUEST,
    DELETE_CATEGORY_SUB_ERROR,
    DELETE_CATEGORY_SUB_SUCCESS
} from '../actionTypes';

const initialState = {
    isLoading: true,
    isError: false,
    errMessage: '',
    categories: [],
}

export default function(state = initialState, action) {
    switch (action.type) {
        case DELETE_CATEGORY_SUB_REQUEST:
        case CREATE_CATEGORY_SUB_REQUEST:
        case UPDATE_CATEGORY_SUB_REQUEST:
        case DELETE_CATEGORY_REQUEST:
        case UPDATE_CATEGORY_REQUEST:
        case CREATE_CATEGORY_REQUEST:
        case GET_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                isLoading: false,
            }
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: [action.payload, ...state.categories],
                isLoading: false,
            }
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: state.categories.map(category => {
                    if (category.code === action.payload.code) {
                        return action.payload;
                    } else {
                        return {...category}
                    }
                }),
                isLoading: false,
            }
        case DELETE_CATEGORY_SUB_ERROR:
        case CREATE_CATEGORY_SUB_ERROR:
        case DELETE_CATEGORY_ERROR:
        case UPDATE_CATEGORY_ERROR:
        case CREATE_CATEGORY_ERROR:
        case UPDATE_CATEGORY_SUB_ERROR:
            return {
                ...state,
                isLoading: false,
            }
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: state.categories.filter(item => item.code !== action.payload.code && item.name !== action.payload.name),
                isLoading: false,
            }
        case DELETE_CATEGORY_SUB_SUCCESS:
            const categories = state.categories.map(categorie => {
                const sub = categorie.sub.filter(item => item.code !== action.payload.categorySubCode && item.name !== action.payload.categorySubName)
                return Object.assign(categorie, { sub })
            })
            return {
                ...state,
                categories,
                isLoading: false,
            }
        case GET_CATEGORY_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
    
        default:
            return state;
    }
}