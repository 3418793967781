import React from 'react';
import { PageHeader, Button } from 'antd'

const PageHeaders = ({ title, subTitle, btn }) => {

    const handleClick = () => {
        btn.fnc();
    }

    return (
        <PageHeader
            title={title}
            subTitle={subTitle}
            extra={[
                <Button size={btn.size} type={btn.type} onClick={e => handleClick()} key="1">{btn.icon} {btn.text}</Button>,
            ]}
        />
    );
}

export default PageHeaders;