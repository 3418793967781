import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

import Home from '../components/Home';

const HomePage = () => {
    const { Content } = Layout;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <Home />
            </Content>
        </MainLayout>
    )
}

export default HomePage;