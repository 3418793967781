import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Layout } from 'antd';

import Product from '../components/Product';

const ProductPage = () => {
    const { Content } = Layout;

    return (
        <MainLayout>
            <Content style={{ margin: '24px 16px 0' }}>
                <Product />
            </Content>
        </MainLayout>
    )
}

export default ProductPage;