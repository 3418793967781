import React from 'react';
import { STATIC_IMG_PATH } from '../../constants/config';

const Home = () => {
    return (
        <div style={{ padding: 24, height: '500px', backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="logo">
				<img src={STATIC_IMG_PATH+'logo-big-one.png'} alt="SPC" />
			</div>
        </div>
    );
}

export default Home;