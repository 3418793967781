import jwt_decode from 'jwt-decode';
import { AUTHENTICATE, DEAUTHENTICATE } from '../actionTypes';

const initialState = {
    token: null,
    user: {},
    isAuthenticated: false,
    isLoading: true,
}

export default function(state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                token: action.payload,
                user: jwt_decode(action.payload),
                isAuthenticated: true,
                isLoading: false,
            }
        case DEAUTHENTICATE:
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
            }
        default:
            return state;
    }
}